.nav-secondary {
  $list-border-color: $color-white-11;

  // all list items
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  > ul {
    border-top: 1px solid $list-border-color;
  }

  > ul > li > a {
    border-bottom: 1px solid $list-border-color;

    + ul {
      border-bottom: 1px solid $list-border-color;
    }
  }

  ul {
    ul {
      ul {
        margin-left: rem-calc(26);
      }
    }
  }

  // all links
  a {
    @include font-nunito-bold;
    @include button-ripple($color-primary-2);
    display: block;
    padding: rem-calc(18) rem-calc(28);
    text-decoration: none;

    &:focus {
      &:not(:active):not(.button--active) { // sass-lint:disable-line force-pseudo-nesting
        box-shadow: inset 0 0 0 1px $color-primary-2;
        outline: none;
      }
    }

    &:hover {
      &:not(:active):not(.button--active) { // sass-lint:disable-line force-pseudo-nesting
        background-color: $color-white-6 !important; // sass-lint:disable-line no-important
      }
    }
  }

  [aria-current] {
    box-shadow: inset rem-calc(10) 0 0 0 $color-primary-2 !important; // sass-lint:disable-line no-important
    color: $color-black;
  }
}

.nav-secondary__toggle {
  position: relative;

  > ul {
    border-width: 0 !important; // sass-lint:disable-line no-important
    display: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 500ms ease-out;
  }

  > a {
    padding-right: rem-calc(58);
  }

  &[aria-expanded='true'] {
    background: $color-white;

    > ul {
      border-width: 1px !important; // sass-lint:disable-line no-important
      display: block;
      max-height: 100%;
    }
  }
}

.nav-secondary__icon {
  cursor: pointer;
  line-height: rem-calc(61);

  &::after {
    content: '+';
    cursor: pointer;
    display: block;
    padding: 0 rem-calc(24);
    position: absolute;
    right: 0;
    top: 0;

    .nav-secondary__toggle[aria-expanded='true'] > & { // sass-lint:disable-line force-attribute-nesting
      content: '-';
    }
  }

  &.focus-visible {
    &::after {
      box-shadow: inset 0 0 0 2px $color-primary-2;
    }
  }
}
