.l-listing-controls {
  @media screen and (min-width: 768px) {
    display: flex;

    .l-listing-controls__search {
      flex-grow: 1;
    }

    .l-listing-controls__facets {
      flex-grow: 0;
      flex-shrink: 0;
      margin-left: rem-calc(30);
      width: rem-calc(250);
    }
  }
}
