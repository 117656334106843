.l-blog-controls {
  @include clearfix;
  border-top: 1px solid $color-white-11;
  padding-top: rem-calc(24);
  @media screen and (max-width: 480px) {
    @include layout-extend;
  }
}

.l-blog-controls__next {
  float: right;
  max-height: 52px;
}

.l-blog-controls__prev {
  float: left;
  max-height: 52px;
}
