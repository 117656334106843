.nav-breadcrumb {
  @include font-nunito-bold;
  color: $color-primary-9;
  font-size: rem-calc(16);

  a {
    color: $color-primary-2;
    text-decoration: none;
  }

  ol {
    list-style: none;
    margin: 0;
    padding-left: 0;
  }

  li {
    display: inline;

    + li {
      &::before {
        content: '/';
        display: inline-block;
        margin: 0 5px;
      }
    }
  }

  [aria-current] {
    @include font-nunito-regular;
    color: $color-primary-9;

    &:focus,
    &:hover {
      background: transparent;
    }
  }
}

.breadcrumb-ellipsis {
  [aria-current] {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap;
    width: 200px;
  }
}
