.l-2-tablet {
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    @include layout-flex-grid(2, 15);

    > * {
      max-width: 50%;
    }
  }
}

.l-4-desktop {
  @media screen and (min-width: 1024px) {
    @include layout-flex-grid(4, 15);

    > * {
      max-width: 25%;
    }
  }
}
