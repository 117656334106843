$height-underline: 5px;

.nav-tabs {
  padding-bottom: rem-calc(36);

  &__tabs {
    [role='tablist'] {
      // sass-lint:disable-block no-url-domains,no-url-protocols
      @include scrollable-container;
      background-image: url('../images/pixel-grey.png');
      background-position: 0 calc(100% - 2px);
      background-repeat: repeat-x;
      margin-bottom: rem-calc(40);
      padding-bottom: $height-underline;
      @media screen and (min-width: 768px) {
        @include scrollable-container-reset;
      }
    }

    [role='tab'] {
      @include scrollable-item;
      color: $color-primary-9;
      cursor: pointer;
      font-size: rem-calc(24);
      line-height: 2;
      margin-left: rem-calc(10);
      padding-right: rem-calc(40, 16);

      position: relative;

      &:first-child {
        margin-left: 0;
      }

      &::after {
        background-color: transparent;
        border-radius: 6px;
        content: '';
        display: block;
        height: $height-underline;
        left: 0;
        pointer-events: none;
        position: absolute;
        transition: background .5s;
        width: 100%;
      }

      &:hover {
        &::after {
          background-color: $color-primary-2;
        }
      }
    }

    [aria-selected='true'] {
      color: $color-black;

      &::after {
        background-color: $color-primary-2;
      }
    }
  }
}
