// DEFAULT

.carousel--default {
  padding-bottom: rem-calc(36);
  position: relative;

  .swiper-slide {
    align-self: stretch;
    height: auto;

    > div {
      height: 100%;

      > .card-1 {
        height: 100%;
      }
    }
  }

  .swiper-container {
    margin: -5vw;
    padding: 5vw;
    @media screen and (min-width: 768px) {
      // So we can see box-shadow on cards
      margin: -5px;
      padding: 5px;
    }
  }

  .swiper-prev,
  .swiper-next {
    cursor: pointer;
    display: none;
    position: absolute;
    top: calc(50% - 25px);
    z-index: 1;

    &.swiper-button-disabled {
      display: none;
    }

    @media screen and (min-width: 768px) {
      display: block;
    }
  }

  .swiper-prev {
    left: rem-calc(-25);
    transform: scaleX(-1);
  }

  .swiper-next {
    right: rem-calc(-25);
  }

  .swiper-pagination {
    bottom: 0;
    position: absolute;
    text-align: center;
    transform: none;
    transition: none;
    width: 100%;
    z-index: 1;
  }

  .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      margin: 0 4px;
    }
  }

  .swiper-pagination-bullet-active {
    background: $color-primary-2;
  }

}

// ADS

.carousel--ads-pre-init {
  max-width: 480px;

  .carousel-item {
    display: none;

    &:first-child {
      display: block;
    }
  }
}

.carousel--ads {
  max-width: 480px;
}

// FEATURED ALERTS
.carousel--featured-alerts-pre-init {
  .carousel-item {
    display: none;

    &:first-child {
      display: block;
    }
  }
}

.carousel--featured-alerts {
  .swiper-container {
    margin: -5vw;
    padding: 5vw;
    @media screen and (min-width: 768px) {
      // So we can see box-shadow on cards
      margin: -5px;
      padding: 5px;
    }
  }
}

// PHOTO - TOP

.carousel--photo-top {
  border-bottom: 2px solid $color-black;
  margin-bottom: rem-calc(22);
  padding-bottom: rem-calc(22);
  position: relative;

  .swiper-prev,
  .swiper-next {
    $rem: rem-calc(100);
    cursor: pointer;
    display: none;
    position: absolute;
    top: calc(50% - #{$rem});
    z-index: 1;

    &.swiper-button-disabled {
      display: none;
    }

    @media screen and (min-width: 768px) {
      display: block;
    }
  }

  .swiper-prev {
    left: rem-calc(-25);
    transform: scaleX(-1);
  }

  .swiper-next {
    right: rem-calc(-25);
  }

  .swiper-slide__body {
    padding: rem-calc(22) 0;
  }

  .swiper-slide__body-title {
    @include font-nunito-bold;
    margin-bottom: rem-calc(11);
  }

  .swiper-pagination {
    bottom: auto;
    left: auto;
    right: 0;
    top: -2rem;
    width: auto;
    z-index: 4;
  }

  .modal--dark & {
    @include font-smoothing;
    border-bottom-color: $color-white;
    color: $color-white;
    margin-top: rem-calc(22);
  }

  img {
    width: 100%;
  }

}

// PHOTO - BOTTOM

.carousel--photo-bottom {
  position: relative;

  .swiper-slide {
    cursor: pointer;
    width: 120px;
  }

  .swiper-slide__thumbnail {
    border: 5px solid transparent;
    transition: border-color .3s ease-in-out;
  }

  .swiper-slide-thumb-active {
    .swiper-slide__thumbnail {
      border-color: $color-primary-5;
    }
  }
}
