// TYPE

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin fluid-prop($prop, $min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      #{$prop}: $min-font-size;
      @media screen and (min-width: $min-vw) {
        #{$prop}: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        #{$prop}: $max-font-size;
      }
    }
  }
}

@function rem-calc($size, $base: 22) {
  $rem-size: strip-unit($size) / $base;
  @return #{$rem-size}rem;
}

@mixin font-smoothing {
  // sass-lint:disable-block no-misspelled-properties,no-vendor-prefixes
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -o-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

// GRADIENT - BORDER

@mixin border-gradient-top($width, $color-from, $color-to) {
  border-bottom-width: 0;
  border-image: linear-gradient(to right, $color-from, $color-to) 1 stretch;
  border-left-width: 0;
  border-right-width: 0;
  border-top: $width solid $color-from;
}

// GRADIENT - BACKGROUND

@mixin background-gradient-horizontal($angle, $color-from, $color-to) {
  background: linear-gradient($angle, $color-from, $color-to), $color-from;
}



@mixin flex-columns-with-gap($columns, $column-gap) {
  // eg. flex-basis: calc(100% / 2 - 10px)
  flex-basis: calc((100% / #{$columns}) - ((#{$columns} - 1) * #{$column-gap} / #{$columns}));
}
