.module-group {
  margin-bottom: calc(10px + 3vw);
  position: relative;

  .module--dark {
    margin: 0;
  }
}

.module-group__img {
  picture {
    flex: 1 1 auto;

    img {
      max-height: 30vw;
      object-fit: cover;
      width: 100%;
    }
  }

  .object-fit-polyfill {
    min-height: 30vw;
  }
  @media screen and (min-width: 1200px) {
    bottom: 0;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;

    .object-fit-polyfill {
      min-height: 0;
    }

    picture {
      flex: 1 1 auto;

      img {
        height: 100%;
        max-height: none;
        object-fit: cover;
      }
    }
  }
}

.module-group__content {
  @media screen and (min-width: 1200px) {
    padding-right: 50%;
  }
}
