@mixin button-ripple($from-color) {
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0);

  &::before {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: .3;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: scale(0, 0);
    transition: 0s;
    width: 100%;
  }

  &:active,
  &.button--active {
    &::before {
      background-image: radial-gradient(circle, $from-color 10%, transparent 10.01%);
      background-position: 0;
      background-repeat: no-repeat;
      opacity: 0;
      transform: scale(10, 10);
      transition: transform 1s, opacity 1s;
    }
  }
}

@mixin button-reset {
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  padding: 0;
}
