@function padding-aspect($width, $height) {
  @return $height / $width * 100%;
}

.iframe {
  height: 0;
  overflow: hidden;
  padding-bottom: padding-aspect(16, 9);
  position: relative;

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.iframe--21x9 {
  padding-bottom: padding-aspect(21, 9);
}

.iframe--16x9 {
  padding-bottom: padding-aspect(16, 9);
}

.iframe--4x3 {
  padding-bottom: padding-aspect(4, 3);
}

.iframe--3x4 {
  padding-bottom: padding-aspect(3, 4);
}

.iframe--form {
  padding-bottom: 35vh;

  @media screen and (max-width: 920px) {
    padding-bottom: 40vh;
  }
}

.iframe--map {
  @media screen and (max-width: 767px) {
    padding-bottom: padding-aspect(1, 1);
  }
}

.iframe--stretch-mobile-only {
  @media screen and (max-width: 767px) {
    @include layout-extend;
  }
}

.iframe--double {
  > div {
    margin-bottom: rem-calc(30);
  }
  @media screen and (min-width: 768px) {
    @include layout-flex-grid(2, 15);
  }
}
