@mixin button--full-width {
  text-align: center;
  width: 100%;
}

@mixin button-1 {
  @include font-nunito-bold;
  @include font-smoothing;
  @include link-color($color-white);
  @include button-ripple($color-white);
  background: linear-gradient(105deg, $color-red-1, $color-primary-6), $color-primary-6;
  border-radius: 20px;
  border-style: none;
  box-shadow: 1px 1px 3px transparentize($color-black, .84);
  display: inline-block;
  line-height: 1;
  padding: rem-calc(10) rem-calc(27);
  text-decoration: none;

  span {
    &::before {
      line-height: 1px !important;  // sass-lint:disable-line no-important
      vertical-align: middle;
    }
  }

  &:focus {
    outline: none;

    &:not(:active):not(.button--active) { // sass-lint:disable-line force-pseudo-nesting
      border: rem-calc(3) solid $color-red-1;
      padding: rem-calc(7) rem-calc(24);
    }
  }

  &:hover {
    &:not(:active):not(.button--active) { // sass-lint:disable-line force-pseudo-nesting
      box-shadow: 1px 1px 3px transparentize($color-black, .84), 0 0 0 6px transparentize($color-red-7, .85);
    }
  }
}

.button-1 {
  @include button-1;
}

@mixin button-2 {
  @include font-nunito-bold;
  @include link-color($color-red-1);
  @include button-ripple($color-red-7);
  border: 1px solid $color-red-1;
  border-radius: 20px;
  display: inline-block;
  padding: rem-calc(10) rem-calc(27);
  text-decoration: none;

  &:focus {
    outline: none;

    &:not(:active):not(.button--active) { // sass-lint:disable-line force-pseudo-nesting
      border-width: 3px;
      padding: rem-calc(8) rem-calc(25);
    }
  }

  &:hover {
    &:not(:active):not(.button--active) { // sass-lint:disable-line force-pseudo-nesting
      background-color: $color-white-1 !important; // sass-lint:disable-line no-important
    }
  }
}

.button-2 {
  @include button-2;
}

@mixin button-3 {
  @include background-gradient-horizontal(150deg, $color-red-1, $color-red-8);
  @include font-smoothing;
  @include link-color($color-white);
  @include button-ripple($color-white);
  border-radius: rem-calc(52);
  box-shadow: 3px 3px 10px transparentize($color-black, .84);
  display: inline-block;
  text-decoration: none;

  .fa {
    color: $color-white;
    font-size: rem-calc(52);
  }

  &:focus {
    outline: none;

    &:not(:active):not(.button--active) { // sass-lint:disable-line force-pseudo-nesting
      border: 3px solid $color-red-1;
      margin: -3px;
    }
  }

  &:hover {
    &:not(:active):not(.button--active) { // sass-lint:disable-line force-pseudo-nesting
      box-shadow: 1px 1px 3px transparentize($color-black, .84), 0 0 0 6px transparentize($color-red-7, .85);
    }
  }
}

.button-3 {
  @include button-3;
}

.button-4 {
  @include background-gradient-horizontal(129deg, $color-primary-2, $color-primary-1);
  @include font-foco-regular;
  @include font-smoothing;
  @include link-color($color-white);
  @include button-ripple($color-white);
  align-items: center;
  border-radius: 7px;
  display: inline-flex;
  font-size: rem-calc(20);
  justify-content: center;
  padding: rem-calc(28);
  text-align: center;
  text-decoration: none;

  &:focus {
    outline: none;

    &:not(:active):not(.button--active) { // sass-lint:disable-line force-pseudo-nesting
      box-shadow: inset 0 0 0 3px $color-blue-3;
    }
  }

  &:focus,
  &:hover {
    @include background-gradient-horizontal(109deg, $color-red-1, $color-primary-6);
  }
}

@mixin button-5-sm {
  @include font-smoothing;
  @include link-color($color-white);
  @include button-ripple($color-white);
  align-items: center;
  background-color: $color-blue-1 !important; // sass-lint:disable-line no-important
  border-radius: 5px;
  box-shadow: 1px 1px 5px transparentize($color-black, .84);
  display: flex;
  flex-direction: column;
  font-size: rem-calc(24);
  justify-content: center;
  padding: rem-calc(20);
  text-align: center;
  text-decoration: none;

  .fa {
    color: $color-white;
    display: block;
    font-size: 75px;
  }

  span {
    @include font-foco-regular;
    display: block;
    flex: 1 1 auto;
    width: 100%;
  }

  &:focus {
    outline: none;

    &:not(:active):not(.button--active) { // sass-lint:disable-line force-pseudo-nesting
      box-shadow: inset 0 0 0 3px $color-blue-3;
    }
  }

  &:focus,
  &:hover {
    @include background-gradient-horizontal(109deg, $color-red-1, $color-primary-6);
  }
}

@mixin button-5-lg {
  @include font-nunito-bold;
  flex-direction: row;
  font-size: rem-calc(20);

  .fa {
    font-size: 50px;
  }

  > span {
    padding-left: 5px;
    text-align: left;
  }
}

.button-5 {
  @include button-5-sm;
  @media screen and (min-width: 768px) {
    @include button-5-lg;
  }
}

.button-5--no-icon {
  text-align: left;
}

.button-6 {
  @include button-5-sm;
}

.button-7 {
  @include font-foco-bold;
  @include font-smoothing;
  @include link-color($color-white);
  @include button-ripple($color-white);
  background-color: $color-blue-1 !important; // sass-lint:disable-line no-important
  border-radius: 5px;
  box-shadow: 0 3px 6px transparentize($color-black, .84);
  display: block;
  padding: rem-calc(16) rem-calc(20);
  text-decoration: none;

  &:focus {
    outline: none;

    &:not(:active):not(.button--active) { // sass-lint:disable-line force-pseudo-nesting
      box-shadow: inset 0 0 0 3px $color-blue-3;
    }
  }

  &:hover {
    @include background-gradient-horizontal(109deg, $color-red-1, $color-primary-6);
  }
}

.button-8 {
  @include font-nunito-bold;
  @include link-color($color-primary-2);
  @include button-ripple($color-primary-2);
  display: flex;
  justify-content: flex-end;
  padding: rem-calc(27) rem-calc(5) rem-calc(27) rem-calc(21);
  position: relative;
  text-decoration: none;
  z-index: 1;

  .fa {
    color: $color-primary-2;
    display: block;
    font-size: 30px;
    margin-top: -2px;
  }

  > span {
    display: block;
    flex: 1 1 auto;
    text-align: left;
  }

  &:focus {
    outline: none;

    &:not(:active):not(.button--active) { // sass-lint:disable-line force-pseudo-nesting
      box-shadow: inset 0 0 0 1px $color-primary-2;
    }
  }

  &:hover {
    &:not(:active):not(.button--active) { // sass-lint:disable-line force-pseudo-nesting
      background-color: $color-white-6 !important; // sass-lint:disable-line no-important
    }
  }
}

.button-9 {
  text-decoration: none;
  @media screen and (max-width: 767px) {
    @include button-1;
    margin-top: rem-calc(20);
  }
  @media screen and (min-width: 768px) {
    &::after {
      @include icon-base;
      color: $color-primary-2;
      content: '\f01d';
    }

    span {
      text-decoration: underline;
    }
  }

}

@mixin button-10 {
  @include font-nunito-bold;
  @include fluid-type(1024px, 1800px, 16px, 22px);
  @include font-smoothing;

  @include button-ripple($color-white);
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  overflow: visible;
  padding: rem-calc(14);
  text-decoration: none;

  &:focus {
    &:not(:active) {
      box-shadow: inset 0 0 0 3px $color-blue-3;
      outline: none;
    }
  }

  &:active {
    outline: none;
  }

  &:hover {
    @include background-gradient-horizontal(109deg, $color-red-1, $color-primary-6);
  }

  &[aria-expanded='true'] {
    background: transparentize($color-black, .5) !important; // sass-lint:disable-line no-important

    &:focus {
      box-shadow: none;
    }
  }
}

.button-10 {
  @include button-10;
}

.button--full-width {
  @include button--full-width;
}

.button-icon {
  .fa {
    margin-right: rem-calc(5);
  }
}
