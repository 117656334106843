@mixin clearfix {
  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

@mixin layout-extend {
  margin-left: -5vw;
  margin-right: -5vw;
}

@mixin layout-extend-right {
  margin-right: -5vw;
}

@mixin layout-extend-right-reset {
  margin-right: 0;
}

@mixin layout-flex-container($wrap, $column-gap: 0) {
  display: flex;
  flex-wrap: #{$wrap};
  margin: 0 rem-calc(-$column-gap);
}

@mixin layout-flex-columns($columns, $column-gap: 0) {
  flex: 1 0 auto;
  padding: 0 rem-calc($column-gap);
  width: #{100% / $columns};
}

@mixin layout-flex-grid($columns, $column-gap) {
  @include layout-flex-container(wrap, $column-gap);

  > * {
    @include layout-flex-columns($columns, $column-gap);
    display: flex;
    flex-direction: column;

    > * {
      flex: 1 1 auto;
      min-height: 1px; // for IE11
    }
  }
}

@mixin layout-module-spacing {
  .module {
    margin-bottom: calc(10px + 3vw);
    padding: 0 5vw;
  }

  .module--lg {
    margin-bottom: calc(10px + 4vw);
  }

  .module--sm {
    margin-bottom: calc(10px + 2vw);
  }

  .module--xs {
    margin-bottom: calc(10px + 1vw);
  }

  .module--xxs {
    margin-bottom: calc(10px);
  }

  .module--light,
  .module--dark {
    padding: 5vw;
  }

  @media screen and (min-width: $breakpoint-desktop-menu-min) {
    .module {
      padding-bottom: 0;
      padding-top: 0;
    }

    .module--light,
    .module--dark {
      padding-bottom: rem-calc(50);
      padding-top: rem-calc(50);
    }

    .module--underlap {
      // These values are related to the size of the images in feature box hover
      margin-top: -23vw;
      padding-top: 23vw;
    }
  }

  @media screen and (min-width: 1600px) {
    .module {
      margin-bottom: rem-calc(50);
    }

    .module--lg {
      margin-bottom: rem-calc(75);
    }

    .module--sm {
      margin-bottom: rem-calc(25);
    }

    .module--xs {
      margin-bottom: rem-calc(17.5);
    }

    .module--xxs {
      margin-bottom: rem-calc(10);
    }

  }
}

@mixin layout-site-max {
  margin: 0 auto;
  max-width: 1600px;
}

@mixin layout-site-padding {
  padding: 5vw;
}

@mixin layout-site-padding-horizontal {
  padding-left: 5vw;
  padding-right: 5vw;
}

@mixin layout-site-padding-vertical-md {
  padding-bottom: 3vw;
  padding-top: 3vw;
}

@mixin layout-scrollable($column-gap, $width) {
  -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-vendor-prefixes
  $column-gap-rem: rem-calc($column-gap);
  display: flex;
  flex-wrap: nowrap;
  margin: 0 -5vw;
  overflow-x: auto;
  padding-left: calc(5vw - #{$column-gap-rem});

  > div {
    flex: 1 0 auto;
    padding: rem-calc($column-gap);
    width: #{$width}px;
  }
}

@mixin layout-flex-nowrap($column-gap) {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 rem-calc(-$column-gap);

  > * {
    flex: 1 1 auto;
    padding: 0 rem-calc($column-gap);
  }
}

@mixin layout-flex-equal-height {
  > div {
    display: flex;

    > div {
      flex: 1 1 auto;
    }
  }
}
