.header__tertiary {
  display: flex;

  .tertiary__logo {
    img {
      width: 50px;
    }
  }

  .tertiary__navigation {
    align-self: center;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    ul {
      display: inherit;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        &:not(:last-child) {
          padding-right: 20px;
        }
      }
    }
  }
}

.footer__tertiary {
  @include border-gradient-top(1px, $color-primary-2, $color-primary-1);
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0;

  div {
    display: flex;
    flex-basis: 100%;
    flex-grow: 1;
    @media screen and (min-width: 650px) {
      flex-basis: auto;
    }
  }

  .tertiary__copyright {
    order: 3;
    @media screen and (min-width: 650px) {
      order: 1;
    }
  }

  .tertiary__navigation {
    order: 2;
    @media screen and (min-width: 650px) {
      justify-content: center;
    }
  }

  .tertiary__social {
    order: 1;
    @media screen and (min-width: 650px) {
      justify-content: flex-end;
      order: 3;
    }
  }

  .tertiary__navigation,
  .tertiary__social {
    ul {
      list-style: 0;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
      }
    }
  }
}
