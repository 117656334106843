html {
  // sass-lint:disable-block no-vendor-prefixes
  @include fluid-type(320px, 1600px, 18px, 20px);
  -ms-overflow-style: scrollbar;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: Arial, sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

address {
  font-size: rem-calc(18);
  font-style: normal;
  line-height: 1.4;
}

p {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
}

button {
  cursor: pointer;
}
