.special-event__content {
  display: flex;
  flex-wrap: nowrap;
}

.special-event__inner_content {
  display: inline-block;
  margin-top: rem-calc(30px);
  padding: 0 5%;
}

.special-event__date-month {
  float: left;
  margin-top: rem-calc(35px);
  padding: rem-calc(15px);

  .date__month {
    text-transform: uppercase;
  }
}
