%button-group {
  align-items: center;
  color: $color-primary-8;
  display: flex;
  text-decoration: none;

  &:focus,
  &:hover {
    outline: none;
    text-decoration: underline;
  }
}

%button-group--text-left {
  .button-group__text {
    padding-right: rem-calc(15);
    text-align: right;
  }
}

%button-group--text-right {
  .button-group__text {
    order: 1;
    padding-left: rem-calc(15);
  }

  i {
    transform: rotate(180deg);
  }
}

.button-group {
  @extend %button-group;
}

.button-group__text {
  line-height: 1.25;
}

.button-group--text-left {
  @extend %button-group--text-left;
}

.button-group--text-right {
  @extend %button-group--text-right;
}
