@mixin table-striped($reversed: false) {
  td,
  th {
    padding: rem-calc(18) rem-calc(25);
    text-align: left;
  }

  thead {
    th,
    td {
      white-space: nowrap;
    }
  }

  tbody {
    tr {
      @if $reversed {

        &:nth-child(even) {
          background-color: $color-white-6;
        }

        &:nth-child(odd) {
          background-color: $color-white;
        }
      } @else {

        &:nth-child(odd) {
          background-color: $color-white-6;
        }

        &:nth-child(even) {
          background-color: $color-white;
        }
      }
    }

    td {
      vertical-align: top;
    }
  }
}

@mixin table--default {

  td,
  th {
    border-bottom: 1px solid $color-white-7;
    padding: rem-calc(18) rem-calc(25);
    text-align: left;
  }

  thead {
    th,
    td {
      background: $color-white-6;
      white-space: nowrap;
    }
  }

  tbody {
    tr {
      &:last-child {
        td,
        th {
          border-bottom: 0;
        }
      }
    }
  }
}

// BASE

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 18px;
  width: 100%;
}

// DEFAULT

.table--default {
  table {
    @include table--default;

    td,
    th {
      padding: rem-calc(18) rem-calc(25);
    }
  }
}

// COMPACT

.table--compact {
  table {
    @include table--default;

    td,
    th {
      // Use important to force the trim cell size when used with other table styles.
      padding: rem-calc(9) rem-calc(25) !important; // sass-lint:disable-line no-important
    }
  }
}

// DATA

.table--data {
  table {
    @include table--default;

    td,
    th {
      text-align: right;

      &:first-child {
        text-align: left;
      }
    }
  }

  // COMBINATION DATA TABLES
  // DATA + STRIPED
  &.table--striped {
    @include table-striped(true);

    td,
    th {
      text-align: right;

      &:first-child {
        text-align: left;
      }
    }
  }
}

// FIXED FIRST COLUMN

.table--fixed-first {
  table {
    td,
    th {
      &:first-child {
        white-space: nowrap;
      }
    }
  }
}

// STRIPED

.table--striped {
  table {
    @include table-striped;
  }
}


// ACCORDION

.accordion__content { // high specificity needed
  div {
    &.table--accordion {
      table {
        tbody {
          tr {
            background-color: transparent;
          }

          td,
          th {
            border: 0;
            padding: rem-calc(10) 0;
          }

          td {
            &:last-child {
              text-align: right;
            }
          }
        }
      }
    }
  }
}



// RESPONSIVE

.table-responsive {
  &.active {
    border-bottom: 1px solid $color-white-10;
  }
}

// TRANSIT SCHEDULE

$table-transit-bar-width: rem-calc(40);
$table-transit-cell-padding: rem-calc(20);
$table-transit-circle-height: rem-calc(16);
$table-transit-circle-radius: rem-calc(16 / 2);
$table-transit-circle-border: rem-calc(16 / 4);

.table--transit-schedule {

  table {
    border-collapse: separate;

    th {
      text-align: left;
    }

    thead {
      th {
        div {
          padding: $table-transit-cell-padding;
          position: absolute;
          top: rem-calc(-70);
        }
      }
    }

    tbody {

      tr {

        td {
          text-align: center;
        }

        td,
        th {
          border-bottom: 1px solid $color-black-5;
          border-right: 1px solid $color-black-5;
          padding: $table-transit-cell-padding;
        }

        th {
          background-color: $color-white-6;
          background-image: linear-gradient($color-black-7, $color-black-7);
          background-origin: border-box;

          background-position: 100% 100%;
          background-repeat: no-repeat;
          background-size: calc(100% - #{$table-transit-bar-width}) 1px;
          border-bottom-color: transparent;
          min-width: 44vw;
          padding: $table-transit-cell-padding $table-transit-cell-padding $table-transit-cell-padding $table-transit-bar-width;
          position: relative;

          @media screen and (min-width: 768px) {
            min-width: rem-calc(250);
          }

          &::before {
            background: $color-primary-2;
            content: '';
            height: calc(100% + 1px);
            left: rem-calc(18);
            position: absolute;
            top: 0;
            width: rem-calc(4);
          }

          &::after {
            background: $color-white;
            border: $table-transit-circle-border solid $color-primary-2;
            border-radius: $table-transit-circle-radius;
            content: '';
            height: $table-transit-circle-height;
            left: rem-calc(12);
            position: absolute;
            top: calc(50% - #{$table-transit-circle-height});
            width: $table-transit-circle-height;
          }

          span {
            font-weight: normal;
          }
        }

        &:first-child {
          th {
            &::before {
              top: 50%;
            }
          }
        }

        &:last-child {
          td,
          th {
            background-image: none;
            border-bottom: 0;
          }

          th {
            &::before {
              height: 50%;
            }
          }
        }

        td { // sass-lint:disable-line no-mergeable-selectors (needs to appear after tr:last-child to override border-bottom: 0)
          &.td--highlight {
            border: 3px solid $color-primary-2;
            border-left-width: 2px;
            border-top-width: 2px;
            box-shadow: 0 0 0 1px $color-primary-2,
            0 -1px 0 1px $color-primary-2,
            -1px 0 0 1px $color-primary-2,
            -1px -1px 0 1px $color-primary-2;
            position: relative;
          }
        }
      }
    }
  }

  > table {
    // Hide table overflow before responsive table initializes
    display: block;
    overflow: hidden;

    thead {
      th {
        div {
          top: 0;
        }
      }
    }
  }
}

// MAPS

.table--maps {
  table {
    @include table-striped;
    @media screen and (max-width: 767px) {
      table,
      tbody,
      tr,
      td {
        display: block;
      }

      tr {
        padding: rem-calc(22);
      }

      td {
        margin-bottom: rem-calc(16.5);
        padding: 0;

        &:last-child {
          margin: 0;
        }
      }
    }
    @media screen and (min-width: 768px) {
      td {
        width: 50%;

        &:first-child,
        &:last-child {
          width: 25%;
        }
      }
    }
  }

  .map-notes {
    @include p-note;
    display: inline-block;
    margin-top: rem-calc(10);
  }

  .link-document {
    display: inline;
    padding: 0;
    text-decoration: underline;
  }
}

.table--drag {
  table {
    cursor: pointer;
  }

  .table-responsive-wrapper--fixed {
    table {
      cursor: auto;
    }
  }
}

// TABLE - NOTES
.table-notes {
  @include p-note;
  background: $color-white;
  padding: rem-calc(30);
}

// TABLE - LEGEND
.table-legend {
  @include p-note;
  background: $color-white;
  padding: rem-calc(30);
  padding-top: 0;

  p {
    margin-bottom: rem-calc(22);

    &:last-child {
      margin-bottom: 0;
    }
  }

  .table-notes & {
    padding: 0;
  }
}

.table-legend__item {
  margin-right: rem-calc(40);
  position: relative;

  &::after {
    border: rem-calc(4) solid $color-primary-2;
    bottom: 0;
    content: '';
    left: 0;
    margin: rem-calc(-2);
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: rem-calc(32);
  }
}

// TABLE - CELLS

.indent-1 {
  display: inline-block;
  padding-left: rem-calc(25);
}
