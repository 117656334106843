.featured-callout {
  @media screen and (min-width: 580px) {
    @include layout-flex-container(nowrap, 20);
  }
}

.featured-callout__item {
  @media screen and (max-width: 579px) {
    margin-bottom: rem-calc(50);
  }
  @media screen and (min-width: 580px) {
    @include layout-flex-columns(2, 20);
  }
}

.featured-callout__title {
  > * {
    @include font-foco-bold;
    font-size: rem-calc(30);
    margin-bottom: rem-calc(35);

    a {
      text-decoration: none;
    }
  }
}

.featured-callout__content {
  p {
    margin-bottom: rem-calc(10);
  }
}
