.img-responsive {
  height: auto;
  max-width: 100%;
}

.img--left-50 {
  width: 100% !important; // sass-lint:disable-line no-important
  @media screen and (min-width: 767px) {
    float: left;
    margin-right: rem-calc(25);
    width: 50% !important; // sass-lint:disable-line no-important
  }
}

.img--left-33 {
  width: 100% !important; // sass-lint:disable-line no-important
  @media screen and (min-width: 767px) {
    float: left;
    margin-right: rem-calc(25);
    width: 33% !important; // sass-lint:disable-line no-important
  }
}

.img--right-50 {
  width: 100% !important; // sass-lint:disable-line no-important
  @media screen and (min-width: 767px) {
    float: right;
    margin-left: rem-calc(25);
    width: 50% !important; // sass-lint:disable-line no-important
  }
}

.img--right-33 {
  width: 100% !important; // sass-lint:disable-line no-important
  @media screen and (min-width: 767px) {
    float: right;
    margin-left: rem-calc(25);
    width: 33% !important; // sass-lint:disable-line no-important
  }
}

.img--full {
  width: 100% !important; // sass-lint:disable-line no-important
}
