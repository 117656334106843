.tourism-events {
  width: 100%;

  thead {
    display: none;
  }
}

.tourism-events__total {
  margin-bottom: rem-calc(24);
}

.tourism-event {
  border-bottom: 1px solid $color-white-11;
  display: flex;
  margin-bottom: rem-calc(12);
  padding-bottom: rem-calc(12);
  @media screen and (max-width: 1024px) {
    @include layout-extend;
    @include layout-site-padding-horizontal;
  }
}

.tourism-event__details {
  display: inline-block;
  margin-left: rem-calc(24);
  margin-right: rem-calc(24);
  width: 100%;

  a {
    text-decoration-line: none;
  }
}

.tourism-event__title {
  margin-bottom: rem-calc(12);
  margin-top: rem-calc(6);
}

.tourism-event__image {
  display: none;
  @media screen and (min-width: 768px) {
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    height: rem-calc(76);
    max-width: rem-calc(120);
    width: 100%;
  }
}

// Paginator
.paginate_button {
  cursor: pointer;

  &:not(.previous):not(.next) { // sass-lint:disable-line force-pseudo-nesting
    @extend %paginate-link;

    &.current {
      background-color: $color-white-1;
      border-color: $color-red-1;
    }

    .button-3 {
      height: 100%;
      width: 100%;
    }

  }

  &.disabled {
    .button-3 {
      @extend %paginate-link--disabled;
    }
  }
}

.dataTables_paginate {
  border-top: 0;
  display: flex;
  justify-content: space-between;
  padding-top: rem-calc(24);
  width: 100%;

  &::after {
    content: none;
  }

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
  }

  span {
    display: inline-flex;

    .paginate_button {
      padding-top: rem-calc(2);
    }
  }

  .ellipsis {
    align-self: center;
    margin-top: rem-calc(-8);
  }

  .next,
  .previous {
    @extend %button-group;

    .button-group__text {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }

  .previous {
    @extend %button-group--text-right;
  }

  .next {
    @extend %button-group--text-left;
  }
}
