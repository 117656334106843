@media screen and (max-width: 1024px) {
  .feature-box-hover {
    display: block;
    margin-bottom: rem-calc(20);
    position: relative;
  }

  .feature-box-hover__img {
    border-radius: 5px;
    overflow: hidden;

    picture {
      display: block;
      position: relative;

      &::before {
        background-image: linear-gradient(
          223deg,
          transparentize($color-black-3, 1) 0%,
          transparentize($color-black-2, .44) 66%,
          transparentize($color-black-1, 0) 100%
        );
        bottom: -1rem;
        content: '';
        left: -1rem;
        position: absolute;
        right: -1rem;
        top: -1rem;
      }
    }

    .object-fit-polyfill {
      min-height: 30vw;
    }

    img {
      max-height: 200px;
      object-fit: cover;
      width: 100%;
    }
  }

  .feature-box-hover__body {
    align-items: flex-end;
    bottom: 0;
    display: flex;
    left: 0;
    margin: 0 rem-calc(10);
    position: absolute;
    right: 0;
    top: 0;
  }

  .feature-box-hover__title {
    @include font-smoothing;
    @include font-foco-regular;
    color: $color-white;
    font-size: rem-calc(30);
    padding: rem-calc(26) rem-calc(34);
    text-decoration: none;
  }

  .feature-box-hover__text {
    display: none;
  }

  .feature-box-hover__link {
    display: none;
  }
}

@media screen and (min-width: 685px) and (max-width: 1024px) {
  .feature-box-hover-list {
    @include layout-flex-container(wrap, 10);
  }

  .feature-box-hover {
    @include layout-flex-columns(2, 10);
  }
}

@media screen and (min-width: 1025px) {
  .feature-box-hover-list {
    @include layout-flex-container(wrap);
  }

  .feature-box-hover {
    @include font-smoothing;
    @include layout-flex-columns(4);
    background: linear-gradient(137deg, $color-red-1 55%, $color-primary-7 100%);
    color: $color-white;
    display: block;
    overflow: hidden;
    position: relative;
    transition: transform .6s, box-shadow .6s;
    z-index: 1;

    a {
      color: $color-white;
      text-decoration: none;
    }

    &:focus,
    &:hover {
      box-shadow: 0 24px 38px 3px transparentize($color-black, .86), 0 9px 46px 8px transparentize($color-black, .88), 0 11px 15px -7px transparentize($color-black, .8);
      outline: none;
      transform: scale(1.054);
      z-index: 2;

      .feature-box-hover__body {
        &::before {
          opacity: 0;
        }
      }

      .feature-box-hover__img {
        opacity: .14;
      }

      .feature-box-hover__title,
      .feature-box-hover__text,
      .feature-box-hover__link {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }

      .feature-box-hover__text {
        transition-delay: .1s;
      }

      .feature-box-hover__link {
        transition-delay: .15s;
      }
    }
  }

  .feature-box-hover__img {
    opacity: 1;
    transition: opacity .6s;

    picture {
      height: 25vw;
    }

    img {
      height: 25vw;
      object-fit: cover;
      width: 100%;
    }
  }

  .feature-box-hover__body {
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    padding: 1.5rem;
    position: absolute;
    right: 0;
    top: 0;

    &::before {
      background-image: linear-gradient(
        223deg,
        transparentize($color-black-3, 1) 0%,
        transparentize($color-black-2, .44) 66%,
        transparentize($color-black-1, 0) 100%
      );
      bottom: -1rem;
      content: '';
      display: block;
      left: -1rem;
      opacity: 1;
      position: absolute;
      right: -1rem;
      top: -1rem;
      transition: opacity .6s;
    }
  }

  .feature-box-hover__title {
    @include font-foco-regular;
    font-size: calc(20px + 10 * ((100vw - 1025px) / 575));
    line-height: 1.15;
    opacity: 1;
    transition: transform .6s;
  }

  .feature-box-hover__text {
    flex: 1 1 auto;
    font-size: calc(18px + 4 * ((100vw - 1025px) / 575));
    line-height: 1.3;
    padding-top: 1rem;
  }

  .feature-box-hover__link {
    font-size: rem-calc(20);

    span {
      text-decoration: underline;
    }

    i {
      display: inline;
      font-weight: bold;
    }
  }

  .feature-box-hover__title,
  .feature-box-hover__text,
  .feature-box-hover__link {
    // IE 11 does not support calc inside translate3d so use two separate translations
    transform: translate3d(0, 25vw, 0) translate3d(0, -4.5em, 0);
  }

  .feature-box-hover__text,
  .feature-box-hover__link {
    opacity: 0;
    transition: opacity .6s, transform .6s;
  }
}
