.nav--move-down {
  display: none;
  max-width: 100% !important;   // sass-lint:disable-line no-important

  @media screen and (min-width: 1024px) {
    animation: bounceup 1800ms infinite;
    bottom: 0;
    display: flex;
    font-size: rem-calc(19);
    justify-content: center;
    padding-bottom: rem-calc(12);
    position: sticky;
    text-decoration: none;
    width: 100%;

    a {
      color: $color-white;
    }

    &.js-is-sticky,
    &.js-is-stuck {
      left: 50%;
      width: auto;

      i {
        left: -50%;
        position: relative;
      }
    }
  }
}

@keyframes bounceup {
  50% {
    transform: translateY(-10px);
  }
}
