.accordion-item {
  line-height: 1.4;
}

.accordion-item__title {
  font-size: rem-calc(30);
  margin-bottom: rem-calc(15);
}

.accordion-item__summary {
  display: initial;

  p {
    font-size: rem-calc(20);
    margin-bottom: rem-calc(22);
  }
}

.accordion-item__body {
  border-left: 1px solid $color-black-4;
  display: block;
  margin-bottom: rem-calc(22);
  padding-left: rem-calc(25);

  p {
    font-size: rem-calc(20);

    + p {
      padding-top: rem-calc(22);
    }
  }
}

.accordion-item__toggle {
  @include font-nunito-bold;
  display: none;

  a {
    text-decoration: none;
  }
}
