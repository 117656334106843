.featured-contact {
  @include card-1;
  font-size: rem-calc(18);
  line-height: 1.3;
  @media screen and (min-width: 768px) {
    padding: rem-calc(40) rem-calc(25) rem-calc(25);
  }
}

.featured-contact__items {
  @media screen and (min-width: 768px) {
    @include layout-flex-container(wrap, 25);
  }
}

.featured-contact__item {
  ul {
    @include list-reset;
  }

  li {
    margin-bottom: rem-calc(15);
  }

  a {
    white-space: nowrap;
  }
  @media screen and (max-width: 767px) {
    padding: rem-calc(25);
  }
  @media screen and (min-width: 768px) {
    @include layout-flex-columns(2, 25);
    margin-bottom: rem-calc(50);
  }
  @media screen and (min-width: 1400px) {
    margin-bottom: rem-calc(25);
    width: 25%;
  }
}

.featured-contact__items2 {
  @media screen and (min-width: 768px) {
    @include layout-flex-container(wrap, 10);
  }
}

.featured-contact__item2 {
  ul {
    @include list-reset;
  }
  @media screen and (max-width: 767px) {
    margin-top: rem-calc(20);
  }
  @media screen and (min-width: 768px) {
    @include layout-flex-columns(2, 10);
    display: flex;
  }
}

.featured-contact__item2-inner {
  @include card-2;
  @media screen and (max-width: 767px) {
    padding: rem-calc(25);
  }
  @media screen and (min-width: 768px) {
    flex: 1 1 auto;
    padding: rem-calc(25);
  }
}

.featured-contact__title {
  > * {
    @include font-foco-regular;
    font-size: rem-calc(24);
    line-height: 1;
    margin-bottom: rem-calc(18);
  }
}

.featured-contact__title-small {
  > * {
    @include font-foco-regular;
    font-size: rem-calc(24);
    line-height: 1;
    margin-bottom: rem-calc(18);
  }
}
