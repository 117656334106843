// styleguide colors

$color-primary-1: #004478;
$color-primary-2: #0075ba;
$color-primary-3: #00acd6;
$color-primary-4: #810026;
$color-primary-5: #b22d39;
$color-primary-6: #e88e48;
$color-primary-7: #efa94d;
$color-primary-8: #242420;
$color-primary-9: #494942;
$color-primary-10: #959486;
$color-primary-11: #cecec8;

// additional colors - whites and light greys

$color-white: #fff;
$color-white-1: #f9e8df;
$color-white-2: #f8f8f8;
$color-white-3: #f2f2f0;
$color-white-4: #eff0f0;
$color-white-5: #eee;
$color-white-6: #ecf5fa;
$color-white-7: #e7eae8;
$color-white-8: #e3e7e5;
$color-white-9: #dbdfdc;
$color-white-10: #ccc;
$color-white-11: #c3c3c3;

// additional colors - blacks and dark greys

$color-black: #000;
$color-black-1: #001b2f;
$color-black-2: #24343f;
$color-black-3: #545454;
$color-black-4: #707070;
$color-black-5: #808080;
$color-black-6: #202020;
$color-black-7: #444;

// additional colors - reds and oranges

$color-red-1: #b42b36;
$color-red-2: #400005;
$color-red-3: #c55330;
$color-red-4: #ffaa46;
$color-red-5: #cb7109;
$color-red-6: #f79528;
$color-red-7: #d87a5d;
$color-red-8: #c55330;

// additional colors - greens

$color-green-1: #346013;
$color-green-2: #67c026;
$color-green-3: #6fb926;

// additional colors - blues

$color-blue-1: #2a628e;
$color-blue-2: #0b60b2;
$color-blue-3: #8dc1e0;
$color-blue-4: #4254a4;
$color-blue-5: #09548a;
$color-blue-6: #001b25;
$color-blue-7: #006cae;
$color-blue-8: #006cad;
$color-blue-9: #c3dfef;
