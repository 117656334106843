
%paginate-link {
  $circle-size: rem-calc(50);
  align-items: center;
  border: 1px solid $color-white-11;
  border-radius: 50%;
  box-shadow: 1px 1px 3px transparentize($color-black, .84);
  color: $color-black;
  display: inline-flex;
  height: $circle-size;
  justify-content: center;
  margin: 0 rem-calc(5);
  text-decoration: none;
  width: $circle-size;
}

%paginate-link--disabled {
  // sass-lint:disable-block no-important
  background: $color-white-11 !important;
  border-color: $color-white-11 !important;
  color: transparentize($color-white, .3) !important;

  .fa {
    color: transparentize($color-white, .3) !important;
  }

  &:hover {
    box-shadow: 1px 1px 3px transparentize($color-black, .84);
    cursor: not-allowed;
  }

  &:active {
    &::after {
      // no ripple
      transition: none;
    }
  }
}

.nav-pagination {
  display: flex;
  flex-direction: row;
  padding-left: 0;

  li {
    list-style-type: none;

    a {
      @extend %paginate-link;

      &:not(.button-3) {
        @include button-ripple($color-red-7);
      }

      &:focus,
      &:hover {
        &:not(:active):not(.button--active) { // sass-lint:disable-line force-pseudo-nesting
          background-color: $color-white-1;
          border-color: $color-red-1;
        }
      }

      &:focus {
        &:not(:active):not(.button--active) { // sass-lint:disable-line force-pseudo-nesting
          border-width: 3px;
          margin: 0 rem-calc(5);
          outline: none;
        }
      }
    }

    &.active {
      a {
        background-color: $color-white-1;
        border-color: $color-red-1;
      }
    }

    &.disabled {
      a {
        @extend %paginate-link--disabled;
      }
    }
  }
}
