.popular-map-embed {
  box-shadow: transparentize($color-black, .84) 1px 1px 10px;

  img {
    width: 100%;
  }
}

.popular-map-embed__container {
  width: 100%;
}

.popular-map-embed__title {
  margin: rem-calc(24);

  h4 {
    @include h4;
  }
}

.popular-map-embed__content {
  display: flex;
  margin: rem-calc(24);
}

.popular-map-embed__link {
  margin: rem-calc(24);
}
