// sass-lint:disable-all
#rCw .widget-box .widget-header {
  background: linear-gradient(122deg, $color-primary-2, $color-primary-1), $color-primary-2 !important;
  #rCw-title.widget-title {
    $rem-left: rem-calc(12);
    @include font-smoothing;
    @include font-nunito-bold;
    color: $color-white !important;
    font-size: rem-calc(20) !important;
    line-height: 48px !important;
    padding-left: calc(1rem - #{$rem-left}) !important;
  }
}
#rCw .widget-toolbar {
  line-height: 48px !important;
}
#rCw .form-control {
  font-size: 16px !important;
}
