@mixin link-color($color) {
  color: $color;

  &:link {
    color: $color;
  }
}

@mixin link-with-dot {
  display: block;
  line-height: 1.5;
  padding-left: rem-calc(20);
  position: relative;

  &::before {
    content: '●';
    font-size: rem-calc(13);
    left: 0;
    position: absolute;
    top: rem-calc(6);
  }
}

@mixin link--reversed {
  color: $color-white;
  text-decoration: none;

  &:focus,
  &:hover {
    background-color: transparentize($color-white, .9);
    outline: none;
  }
}
