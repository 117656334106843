.l-contact {
  > div {
    margin-bottom: rem-calc(30, 16);
  }

  @media screen and (min-width: 650px) {
    @include layout-flex-grid(2, 15);

    > div > div {
      display: flex;
      flex-basis: auto;
    }
  }

  @media screen and (min-width: 1200px) {
    @include layout-flex-grid(4, 15);

    > div {
      margin-bottom: 0;
    }
  }
}
