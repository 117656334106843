%icon-base {
  display: inline-block;
  font-family: 'CityOfReginaWebsiteRedesign2018';
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-rendering: auto;
}


%icon--file-archive-o {
  &::before {
    @extend %icon-base;
    content: '\f024';
  }
}

%icon--file-audio-o {
  &::before {
    @extend %icon-base;
    content: '\f023';
  }
}

%icon--file-excel-o {
  &::before {
    @extend %icon-base;
    content: '\f020';
  }
}

%icon--file-image-o {
  &::before {
    @extend %icon-base;
    content: '\f025';
  }
}

%icon--file-o {
  &::before {
    @extend %icon-base;
    content: '\f021';
  }
}

%icon--file-pdf-o {
  &::before {
    @extend %icon-base;
    content: '\f01f';
  }
}

%icon--file-powerpoint-o {
  &::before {
    @extend %icon-base;
    content: '\f026';
  }
}

%icon--file-text-o {
  &::before {
    @extend %icon-base;
    content: '\f027';
  }
}

%icon--file-video-o {
  &::before {
    @extend %icon-base;
    content: '\f022';
  }
}

%icon--file-word-o {
  &::before {
    @extend %icon-base;
    content: '\f01e';
  }
}

%icon--address-change {
  &::before {
    @extend %icon-base;
    content: '\f00b';
  }
}

%icon--bylaws {
  &::before {
    @extend %icon-base;
    content: '\f00e';
  }
}

%icon--checkmark {
  &::before {
    @extend %icon-base;
    content: '\f007';
  }
}

%icon--chevron {
  &::before {
    @extend %icon-base;
    content: '\f01d';
  }
}

%icon--chevron-down {
  &::before {
    @extend %icon-base;
    content: '\f05c';
  }
}

%icon--chevron-double {
  &::before {
    @extend %icon-base;
    content: '\f01c';
  }
}

%icon--city-council {
  &::before {
    @extend %icon-base;
    content: '\f00d';
  }
}

%icon--close {
  &::before {
    @extend %icon-base;
    content: '\f005';
  }
}

%icon--daily-road-report {
  &::before {
    @extend %icon-base;
    content: '\f00c';
  }
}

%icon--events {
  &::before {
    @extend %icon-base;
    content: '\f010';
  }
}

%icon--exclamation {
  &::before {
    @extend %icon-base;
    content: '\f006';
  }
}

%icon--external-link {
  &::after {
    @extend %icon-base;
    content: '\f018';
  }
}

%icon--facebook {
  &::before {
    @extend %icon-base;
    content: '\f004';
  }
}

%icon--garbage-recycling {
  &::before {
    @extend %icon-base;
    content: '\f015';
  }
}

%icon--info {
  &::before {
    @extend %icon-base;
    content: '\f001';
  }
}

%icon--jobs {
  &::before {
    @extend %icon-base;
    content: '\f014';
  }
}

%icon--leisure {
  &::after {
    @extend %icon-base;
    content: '\f01a';
  }
}

%icon--maps {
  &::before {
    @extend %icon-base;
    content: '\f00f';
  }
}

%icon--permits {
  &::before {
    @extend %icon-base;
    content: '\f013';
  }
}

%icon--parking-tickets {
  &::before {
    @extend %icon-base;
    content: '\f016';
  }
}

%icon--play-button {
  &::before {
    @extend %icon-base;
    content: '\f00a';
  }
}

%icon--print {
  &::before {
    @extend %icon-base;
    content: '\f02a';
  }
}

%icon--scroll {
  &::before {
    @extend %icon-base;
    content: '\f000';
  }
}

%icon--search {
  &::before {
    @extend %icon-base;
    content: '\f009';
  }
}

%icon--switch-directions {
  &::before {
    @extend %icon-base;
    content: '\f02b';
  }
}

%icon--transit-live {
  &::before {
    @extend %icon-base;
    content: '\f011';
  }
}

%icon--transit-schedules {
  &::before {
    @extend %icon-base;
    content: '\f017';
  }
}

%icon--twitter {
  &::before {
    @extend %icon-base;
    content: '\f003';
  }
}

%icon--water-utility {
  &::before {
    @extend %icon-base;
    content: '\f012';
  }
}

%icon--weather {
  &::before {
    @extend %icon-base;
    content: '\f01b';
  }
}

%icon--youtube {
  &::before {
    @extend %icon-base;
    content: '\f002';
  }
}

.icon--chevron-expandable {
  cursor: pointer;

  &[aria-expanded] {
    &::after {
      @include icon-base;
      color: $color-primary-2;
      content: '\f01d';
      font-size: rem-calc(40);
    }
  }

  &[aria-expanded='false'] {
    &::after {
      transform: rotate(90deg);
    }
  }

  &[aria-expanded='true'] {
    &::after {
      transform: rotate(-90deg);
    }
  }
}

.fa-icon--reversed {
  transform: rotate(180deg);
}

.link-social {
  .fa {
    font-size: rem-calc(25);
    padding: rem-calc(3) rem-calc(3) rem-calc(21);
  }
}
