.toggle-grow {
  height: auto;
  overflow: hidden;
  transition: height .3s ease-out;
}

.toggle-grow--collapsed {
  height: 0;
}

.toggle-grow--tablet-visible {
  @media screen and (min-width: 768px) {
    height: auto !important; // sass-lint:disable-line no-important
    outline: none;
  }
}

.toggle-grow-trigger {
  position: relative;
}

.toggle-grow-trigger__text {
  a {
    display: block;
  }
}

.toggle-grow-trigger__icon {
  position: absolute;
  right: rem-calc(-6);
  top: rem-calc(-6);
}

.toggle-grow-trigger--tablet-visible {
  @media screen and (min-width: 768px) {
    display: none !important; // sass-lint:disable-line no-important
  }
}
