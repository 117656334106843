@mixin list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin list-reset-item {
  padding: 0;
}

@mixin list-unstyled {
  @include list-reset;

  ul {
    @include list-reset;
  }

  li {
    @include list-reset-item;
  }
}

@mixin list-scroll($column-gap, $scrollable: true, $spacer-width: 5vw, $basis: rem-calc(160)) {
  @include list-reset;
  @include layout-extend;
  display: flex;

  @if $scrollable {
    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-vendor-prefixes
    overflow: auto;

    &::before,
    &::after {
      content: '';
      display: block;
      flex-basis: $spacer-width;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  li {
    @include list-reset-item;
    display: flex;
    flex-basis: $basis;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 0 $column-gap;
  }

  a {
    flex: 1 1 auto;
  }
}

@mixin list-grid($columns, $column-gap) {
  @include list-reset;
  @include layout-flex-grid($columns, $column-gap / 2);
  margin-bottom: rem-calc(-$column-gap);

  li {
    margin-bottom: rem-calc($column-gap);
  }
}

@mixin list-flex($width, $column-gap) {
  @include list-reset;
  display: flex;
  flex-wrap: nowrap;
  margin: 0 -#{$column-gap};

  > li {
    display: flex;
    flex: 1 0 auto;
    padding: 0 #{$column-gap};
    width: #{$width};

    a {
      flex: 1 1 auto;
    }
  }
}

@mixin list-flex-wrap($width, $column-gap) {
  flex-wrap: wrap;
  margin-bottom: calc(-#{$column-gap} * 2);

  > li {
    display: flex;
    flex: 1 0 auto;
    margin-bottom: calc(#{$column-gap} * 2);
    width: #{$width};
  }
}

@mixin list-border {
  background: $color-white-11;
  content: '';
  height: 2px;
  position: absolute;
  width: 100%;
}

@mixin list-inline($spacing) {
  @include list-unstyled;
  display: flex;
  margin: 0 rem-calc(-$spacing/2);

  li {
    padding: 0 rem-calc($spacing/2);
  }
}

@mixin list-inline-centered($spacing) {
  @include list-inline($spacing);
  align-items: center;
}

@mixin list-vertical($spacing) {
  @include list-unstyled;
  margin-top: rem-calc($spacing);

  li {
    margin-bottom: rem-calc($spacing);
  }
}
