.nav-section {
  // all list items
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  // all links
  a {
    display: block;
    line-height: 1.2;
    text-decoration: none;
  }
}

.nav-section--title {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .nav-section {
    display: block;
  }

  .nav-section__group {
    display: block;
  }

  .nav-section__group__parent {
    display: block;
  }

  .nav-section__group__children {
    > ul {
      display: block;
    }

    > ul > li {
      display: block;
    }
  }

  .nav-section__group__singles {
    > h4 {
      display: block;
    }
  }
}

@media screen and (min-width: 768px) {
  .nav-section {
    columns: 3;
  }

  .nav-section__group {
    display: inline-block;
    margin-bottom: 30px;
    width: 100%;

    &:not(:last-of-type) {
      border-bottom: transparentize($color-black, .5) 1px solid;
    }
  }

  .nav-section__group__parent {
    margin-bottom: 26px;
  }

  .nav-section__group__children {
    > ul {
      margin-bottom: 4px;
    }

    > ul > li {
      margin-bottom: 16px;
    }
  }

  .nav-section__group__singles {
    > h4 {
      margin-bottom: 16px;
    }
  }
}
