.l-carousel {
  @media screen and (max-width: 767px) {
    @include layout-scrollable(10, 250);
    @include layout-flex-equal-height;
  }
  @media screen and (min-width: 768px) {
    @include layout-flex-container(nowrap, 10);
    @include layout-flex-equal-height;
    overflow: hidden;
    padding-bottom: rem-calc(36);

    > div {
      @include layout-flex-columns(3, 10);
    }
  }
  @media screen and (min-width: 1200px) {
    > div {
      @include layout-flex-columns(4, 10);
    }
  }
}
