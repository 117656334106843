.page-footer {
  @include font-smoothing;
  background: $color-primary-8;
  color: $color-white;

  a {
    @include link--reversed;
    color: $color-white;
    text-decoration: none;
  }
}

.page-footer__background {
  background: url('../images/Flourish_400.jpg') center center no-repeat;
  height: 20px;
  width: 100%;
  @media screen and (min-width: 400px) {
    background-image: url('../images/Flourish_800.jpg');
    height: 40px;
  }
  @media screen and (min-width: 800px) {
    background-image: url('../images/Flourish_1200.jpg');
    height: 60px;
  }
  @media screen and (min-width: 1200px) {
    background-image: url('../images/Flourish_1600.jpg');
    height: 80px;
  }
  @media screen and (min-width: 1600px) {
    background-image: url('../images/Flourish_2000.jpg');
    height: 100px;
  }
  @media screen and (min-width: 2000px) {
    background-size: cover;
  }
}

.page-footer__wrapper {
  @include layout-site-max;
  box-sizing: content-box;
  padding: 5vw;
}

.page-footer__nav {
  @include gradient-background-bottom($color-primary-3, $color-blue-4, 2);
  display: none;
  margin-bottom: rem-calc(30);
  @media screen and (min-width: 1024px) {
    display: block;
  }
}

.page-footer__nav-title {
  @include font-foco-regular;
  font-size: rem-calc(40);
  margin-bottom: rem-calc(60);
}

.page-footer__nav-links {
  @include list-unstyled;
  @include layout-flex-container(nowrap, 10);
  padding-bottom: rem-calc(45);

  > li {
    flex: 1 1 auto;
    max-width: 14.25%;

    > a {
      @include font-nunito-bold;
      font-size: calc(16px + 2 * ((100vw - 1025px) / 575));
      @media screen and (min-width: 1600px) {
        font-size: rem-calc(18);
      }
    }
  }

  a {
    display: block;
    font-size: calc(14px + 4 * ((100vw - 1025px) / 575));
    line-height: 1.4;
    padding: rem-calc(10);
    @media screen and (min-width: 1600px) {
      font-size: rem-calc(18);
    }
  }
}

.page-footer__bottom {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 1.4;
  @media screen and (min-width: 1024px) {
    align-items: center;
    flex-direction: row;
    font-size: calc(14px + 4 * ((100vw - 1025px) / 575));
  }
  @media screen and (min-width: 1600px) {
    font-size: rem-calc(18);
  }

  ul {
    @include list-inline-centered(40);
  }
}

.page-footer__copyright {
  color: $color-white-9;
  margin: rem-calc(25, 16) 0;
  order: 2;
  @media screen and (min-width: 1024px) {
    margin: 0;
    order: 0;
  }
}

.page-footer__other {
  align-items: center;
  display: flex;
  order: 3;
  @media screen and (max-width: 500px) {
    align-items: inherit;
    flex-direction: column;

    > div {
      display: flex;
    }
  }
  @media screen and (min-width: 1024px) {
    flex: 1 1 auto;
    justify-content: center;
    order: 0;
  }

  .goog-te-gadget {
    color: $color-white-10;
  }

  .goog-logo-link {
    color: $color-white-10;

    &:link {
      color: $color-white-10;
    }
  }

  select {
    margin-left: rem-calc(40);
  }
}

.page-footer__google_translate {
  padding: 0 rem-calc(30) 0 rem-calc(40);
  @media screen and (max-width: 500px) {
    margin-top: rem-calc(25, 16);
    padding-left: 0;
  }

  img {
    display: inline;
  }
}

.page-footer__social {
  order: 1;
  @media screen and (min-width: 1024px) {
    order: 0;
  }

  a {
    display: inline-block;
  }

  .fa {
    color: $color-white;
    font-size: rem-calc(25);
  }
}
