.spacing-top--xs {
  padding-top: rem-calc(10);
}

.spacing-top--sm {
  padding-top: rem-calc(20);
}

.spacing-top--md {
  padding-top: rem-calc(30);
}

.spacing-top--lg {
  padding-top: rem-calc(40);
}

.spacing-top--xl {
  padding-top: rem-calc(50);
}

.spacing-top--xxl {
  padding-top: rem-calc(60);
}

.spacing-bottom--xs {
  padding-bottom: rem-calc(10);
}
