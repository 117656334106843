.transit-schedule--direction {
  text-align: center;

  .card-2-content-1__subtitle {
    @include h6;
    margin-bottom: rem-calc(10);
  }

  .card-2-content-1__title {
    @include h4;
    margin-bottom: rem-calc(20);

    > span {
      text-transform: capitalize;
    }
  }

  @media screen and (min-width: 1300px) {
    text-align: inherit;

    .card-2-content-1 {
      @include clearfix;
      position: relative;
    }

    .card-2-content-1__text {
      display: inline-block;
      width: calc(100% - 275px);
    }

    .card-2-content-1__title {
      margin-bottom: 0;
    }

    .card-2-content-1__link {
      float: right;
      position: absolute;
      right: rem-calc(20);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.transit-schedule__tab-content {

  .transit-schedule__print {
    float: right;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;

    .transit-schedule__schedule {
      padding-bottom: rem-calc(22);
    }

    .transit-schedule__print {
      float: none;
      order: 2;
    }
  }
}
