.page-outline {
  @media print {
    display: none;
  }
}

.page-outline__header {
  position: relative;
}

.page-outline__title {
  @include font-foco-regular;
  font-size: rem-calc(24);
  line-height: (32 / 24);
}

.page-outline__toggle {
  position: absolute;
  right: 0;
  top: rem-calc(-5);
}

.page-outline__links {
  @include font-nunito-bold;
  @include list-unstyled;

  @media screen and (max-width: 767px) {
    border-top: 1px solid transparentize($color-black, .8);
    margin-top: rem-calc(34);
    padding-top: rem-calc(34);

    li {
      margin-bottom: rem-calc(25);
    }
  }
  @media screen and (min-width: 768px) {
    columns: 2;
    margin-top: rem-calc(15);

    li {
      break-inside: avoid;
      margin-bottom: rem-calc(15);
      page-break-inside: avoid;
    }

    a {
      @include link-with-dot;
      text-decoration: none;
    }
  }
}
