// Reset heading styles

a {
  color: $color-primary-2;
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  line-height: 1;
  margin: 0;
  padding: 0;
}

body {
  @include font-nunito-regular;
}

strong {
  @include font-nunito-bold;
}

::selection {
  background: $color-blue-9;
}

::-moz-selection { // sass-lint:disable-line no-vendor-prefixes
  background: $color-blue-9;
}

// Only set in main content area

@mixin p-note {
  @include font-nunito-regular;
  font-size: rem-calc(16);
  line-height: (24 / 16);
}

@mixin p-intro {
  @include font-nunito-regular;
  font-size: rem-calc(22);
  line-height: (32 / 22);
}

@mixin p-hr {
  background: linear-gradient(180deg, transparent calc(50% - 1px), $color-white-5 calc(50%), transparent calc(50% + 1px));
  margin: rem-calc(30) 0;
  text-align: center;

  span {
    background: $color-white;
    display: inline-block;
    padding: 0 rem-calc(10);
  }
}

@mixin h1 {
  @include font-foco-bold;
  font-size: rem-calc(40);
  line-height: (51 / 40);
}

@mixin h2 {
  @include font-foco-regular;
  font-size: rem-calc(32);
  line-height: (44 / 32);
}

@mixin h3 {
  @include font-foco-bold;
  font-size: rem-calc(28);
  line-height: (36 / 28);
}

@mixin h4 {
  @include font-foco-regular;
  font-size: rem-calc(28);
  line-height: (38 / 28);
}

@mixin h5 {
  @include font-nunito-bold;
  font-size: rem-calc(24);
  line-height: (32 / 24);
}

@mixin h6 {
  @include font-nunito-bold;
  font-size: rem-calc(22);
  line-height: (28 / 22);
}

.content,
.mce-content-body {
  @include clearfix;
  color: $color-primary-8;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    + p {
      margin-top: -6px;
    }
  }

  h1 {
    @include h1;
    // 44 without line-height = 60 margin - 16 bottom line height = 44 margin bottom
    margin-bottom: rem-calc(60 - (76-60));

    + p {
      &.intro {
        margin-top: rem-calc(-8);
      }
    }
  }

  h2 {
    @include h2;
    margin-bottom: rem-calc(30 - (41-30));
  }

  h3 {
    @include h3;
    margin-bottom: rem-calc(30 - (31-24));
  }

  h4 {
    @include h4;
    margin-bottom: rem-calc(30 - (41-30));
  }

  h5 {
    @include h5;
    margin-bottom: rem-calc(30 - (32-24));
  }

  h6 {
    @include h6;
    margin-bottom: rem-calc(30 - (28-22));
  }

  p {
    @include font-nunito-regular;
    font-size: rem-calc(20);
    line-height: (28 / 20);

    &.intro {
      @include p-intro;
    }

    &.note {
      @include p-note;
    }

    &.hr {
      @include p-hr;
    }

    + h2 {
      margin-top: rem-calc(80 - (44-32) - (28-22));
    }

    + h3 {
      margin-top: rem-calc(80 - (36-28) - (28-22));
    }

    + h4 {
      margin-top: rem-calc(80 - (38-28) - (28-22));
    }

    + h5 {
      margin-top: rem-calc(80 - (32-24) - (28-22));
    }

    + h6 {
      margin-top: rem-calc(80 - (28-22) - (28-22));
    }

    + p {
      margin-top: rem-calc(30 - (28-22) - (28-22));

      &.note {
        margin-top: rem-calc(30 - (22-16) - (28-22));
      }
    }

    + ul,
    + ol {
      margin-top: rem-calc(30 - (36-22) - (28-22));
    }

    + table {
      margin-top: rem-calc(30 - (28-22));
    }
  }

  table {
    + h2 {
      margin-top: rem-calc(80 - (63-50));
    }

    + h3 {
      margin-top: rem-calc(80 - (51-40));
    }

    + h4 {
      margin-top: rem-calc(80 - (41-30));
    }

    + h5 {
      margin-top: rem-calc(80 - (32-24));
    }

    + h6 {
      margin-top: rem-calc(80 - (28-22));
    }

    + p {
      margin-top: rem-calc(30 - (28-22));

      &.note {
        margin-top: rem-calc(30 - (22-16));
      }
    }
  }

  ul,
  ol {
    + p {
      margin-top: rem-calc(30 - (28-22) - (36-22));
    }

    + h2 {
      margin-top: rem-calc(80 - (44-32) - (36-22));
    }

    + h3 {
      margin-top: rem-calc(80 - (36-28) - (36-22));
    }

    + h4 {
      margin-top: rem-calc(80 - (38-28) - (36-22));
    }

    + h5 {
      margin-top: rem-calc(80 - (32-24) - (36-22));
    }

    + h6 {
      margin-top: rem-calc(80 - (28-22) - (36-22));
    }
  }

  ol,
  ul {
    font-size: rem-calc(20);
    line-height: (36 / 20);
    margin: 0 0 0 22px;
    padding: 0;

    li {
      ol,
      ul {
        margin-left: 40px;
        margin-top: 14px;
      }
    }
  }

  ol {
    li {
      list-style-type: decimal;

      li {
        list-style-type: lower-alpha;

        li {
          list-style-type: lower-roman;
        }
      }
    }
  }

  ul {
    list-style: none;

    li {
      display: block;

      &::before {
        color: $color-red-1;
        content: '●';
        display: inline-block;
        font-size: 14px;
        margin-left: -22px;
        position: relative;
        top: -3px;
        width: 22px;
      }

      li {
        &::before {
          content: '○';
        }

        li {
          &::before {
            content: '■';
          }
        }
      }
    }
  }
}

#breadcrumb { // sass-lint:disable-line no-ids
  .content {
    h1 {
      margin-bottom: 0;

      & + p {
        &.intro {
          // 44 without line-height = 60 margin - 16 bottom line height = 44 margin bottom
          margin-top: rem-calc(60 - (76-60));
        }
      }
    }
  }
}

.content__intro {
  p {
    @include p-intro;
    margin-bottom: rem-calc(22);
    margin-top: rem-calc(-8);
  }
}

.content--dark {
  @include font-smoothing;
  color: $color-white;
}

.nowrap {
  white-space: nowrap;
}

.text-right {
  text-align: right;
}
