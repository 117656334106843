@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes slideOut {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  1% {
    visibility: visible;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }

  99% {
    visibility: hidden;
  }

  100% {
    opacity: 0;
  }
}

@keyframes rippleDrop {
  100% {
    transform: scale(2);
    //opacity: 0;
  }
}

.animate-opacity {
  opacity: 0;
  transition: opacity .5s ease;
}
