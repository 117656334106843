// sass-lint:disable-all
.org-opencms-gwt-client-ui-css-I_CmsLayoutBundle-I_CmsToolbarCss-hideButtonShowSmallElements {

  margin-top: 52px;

  // Provide space so OpenCMS bar and site bars do not overlap content
  // padding-top: 130px !important;
  min-height: unset !important;

  // This will disable position:relative added by Google translate.
  // Relative positioning was messing up editor dropdown positioning
  position: static !important;

  // Provide space so OpenCMS bar does not overlap offcanvas menu and header bar
  .page-header,
  .offcanvas {
    top: 52px;
  }

  .offcanvas {
    height: calc(100% - 52px);
  }
}

.page-banner__wrapper {
  .container,
  .container--empty {
    min-height: 150px;
    padding-top: 75px;
  }
}

.org-opencms-ade-containerpage-client-ui-css-I_CmsLayoutBundle-I_CmsContainerpageCss-dragElement {
  .org-opencms-gwt-client-ui-css-I_CmsDirectEditCss-optionBar {
    z-index: 3 !important;
  }
}

@media screen and (max-width: 767px) {
  .org-opencms-ade-containerpage-client-ui-css-I_CmsLayoutBundle-I_CmsContainerpageCss-dragElement.module--overflow {
    overflow: hidden;

    .l-scrollable,
    .l-carousel {
      position: relative;
    }
  }
}
