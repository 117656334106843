a  {
  &[rel*='external'] {
    &:not(.link-document):not(.link-social):not(.feature-box-hover) { // sass-lint:disable-line force-pseudo-nesting
      @extend %icon--external-link;

      &::after {
        font-weight: bold;
        text-decoration: underline;
      }

      // IE11 needs the underline to be set on a pseudo element before it can remove it.
      //sass-lint:disable-block no-mergeable-selectors
      &::after {
        text-decoration: none;
      }
    }
  }

  &.link--reversed {
    @include link--reversed;
  }

  &.link-with-dot {
    @include link-with-dot;
  }
}

.link-document {
  @include button-ripple($color-primary-2);
  display: block;
  padding: rem-calc(15);
  position: relative;
  text-decoration: none;

  &:focus {
    &:not(:active):not(.button--active) { // sass-lint:disable-line force-pseudo-nesting
      box-shadow: inset 0 0 0 2px $color-primary-2;
    }
  }

  &:hover {
    background-color: transparentize($color-primary-2, .89);
  }
}

.link-document__icon {
  color: $color-black;
  font-size: rem-calc(44);
  left: rem-calc(15);
  position: absolute;
  top: rem-calc(15);

  i {
    display: block;
  }
}

.link-document__body {
  padding: 0 0 0 rem-calc(54);
}

.link-document__title {
  @include font-nunito-bold;
  font-size: rem-calc(22);
  margin-bottom: rem-calc(5);
}

.link-document__size {
  color: $color-black-6;
  display: block;
  font-size: rem-calc(16);
}

.js-focus-visible :focus:not(.focus-visible) { // sass-lint:disable-line force-pseudo-nesting
  outline: none;
}
