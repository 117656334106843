.overlay-search {
  padding: rem-calc(30);
  position: relative;
}

.overlay-search__form {
  margin: 4vw auto 0;
  max-width: 1280px;
  position: relative;
}

.overlay-search__navigation {
  @media screen and (min-width: 768px) {
    display: flex;
    margin: 0 -20px;
  }
}

.overlay-search__navigation-group {
  @include font-smoothing;

  @media screen and (max-width: 767px) {
    border-top: 1px solid transparentize($color-white, .5);
    margin-top: rem-calc(20);
  }
  @media screen and (min-width: 768px) {
    padding: 0 20px;
    width: 50%;
  }
}

.overlay-search__navigation-items {
  @include list-reset;

  a {
    @include font-foco-bold;
    color: $color-white;
    display: block;
    text-decoration: none;
  }

  @media screen and (max-width: 767px) {
    a {
      padding: rem-calc(10) 0;
    }
  }

  @media screen and (min-width: 768px) {
    a {
      background-color: transparentize($color-white, .8);
      border-radius: rem-calc(5);
      margin: rem-calc(15) 0;
      padding: rem-calc(20);
    }
  }
}

.overlay-search__title {
  display: none;
  @media screen and (min-width: 768px) {
    @include h1;
    @include font-smoothing;
    color: $color-white;
    display: block;
    font-size: rem-calc(30);
    margin-bottom: rem-calc(40);
    padding-right: rem-calc(60);
  }
}

.overlay-search__subtitle {
  @include font-foco-regular;
  color: $color-white;
  font-size: rem-calc(30);
  margin: rem-calc(30) 0 rem-calc(20);
  @media screen and (min-width: 768px) {
    margin-bottom: rem-calc(30);
  }
}

.overlay-search__input {
  @media screen and (max-width: 767px) {
    margin-right: rem-calc(70);
  }
  @media screen and (min-width: 768px) {
    margin-bottom: rem-calc(40);
  }
}

.overlay-search__close {
  @include button-reset;
  color: $color-white;
  font-size: rem-calc(56);
  @media screen and (max-width: 767px) {
    position: absolute;
    right: 0;
    top: 0;
  }
  @media screen and (min-width: 768px) {
    position: absolute;
    right: 0;
    top: rem-calc(10);
  }
}
