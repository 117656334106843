.photo-gallery-preview {
  position: relative;
}

.photo-gallery-preview__image {
  img {
    width: 100%;
  }
}

.photo-gallery-preview__link {
  bottom: rem-calc(20);
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  @media screen and (min-width: 768px) {
    left: auto;
    right: rem-calc(20);
  }
}
