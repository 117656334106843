.block--city-council {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    @media screen and (min-width: 768px) {
      column-gap: rem-calc(30);
      columns: 2;
      margin-right: rem-calc(30);
    }
  }

  li {
    border-bottom: 1px solid transparentize($color-white, .8);
    break-inside: avoid;
  }

  a {
    @include font-nunito-bold;
    @include link--reversed;
    display: block;
    padding: rem-calc(10) 0;
    text-decoration: none;

    span {
      @include font-nunito-regular;
    }
    @media screen and (min-width: 1200px) {
      font-size: calc(14px + 8 * ((100vw - 1200px) / 400));

      span {
        font-size: calc(13px + 5 * ((100vw - 1200px) / 400));
      }
    }
    @media screen and (min-width: 1600px) {
      font-size: rem-calc(20);

      span {
        font-size: rem-calc(16);
      }
    }
  }
}
