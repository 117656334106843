.l-content {
  @include layout-module-spacing;
  display: block;

  main > :first-child {
    margin-top: calc(10px + 3vw);
  }

  .module__inner {
    @include layout-site-max;
  }
}
