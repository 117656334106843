.offcanvas-bg {
  @include background-gradient-horizontal(166deg, $color-primary-2, $color-primary-1);
  opacity: 0;
  position: fixed;
  transition: opacity .3s ease-in-out;
  z-index: 1;

  &.is-open {
    bottom: 0;
    left: 0;
    opacity: .5;
    right: 0;
    top: 0;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }

  @media print {
    display: none;
  }
}

.offcanvas {
  background: linear-gradient(36deg, $color-blue-7, $color-blue-8 10%, $color-primary-1 40%, $color-primary-1);
  @media screen and (max-width: $breakpoint-offcanvas-max) {
    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-vendor-prefixes
    backface-visibility: hidden;
    height: 100%;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    transform: translateX(100%);
    width: 100%;
    z-index: 10;

    &.is-open {
      animation-duration: .3s;
      animation-name: slideIn;
      transform: translate(0, 0);
    }

    &.is-closed {
      animation-duration: .3s;
      animation-name: slideOut;
      transform: translateX(100%);
    }
  }
  @media screen and (min-width: $breakpoint-desktop-menu-min) {
    bottom: 0;
    left: 0;
    max-height: 100vh;
    opacity: 0;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    z-index: 10;

    &.is-open {
      animation-duration: .3s;
      animation-name: fadeIn;
      opacity: 1;
      visibility: visible;
    }

    &.is-closed {
      animation-duration: .3s;
      animation-name: fadeOut;
      opacity: 0;
      visibility: hidden;
    }
  }
  @media print {
    display: none;
  }
}

.offcanvas--light {
  background: $color-white;
}
