$height-input-icon: 32;
$height-input-border-bottom: 2;

// INPUTS

@mixin control-input-remove-x {
  // sass-lint:disable-block no-vendor-prefixes
  &::-ms-clear {
    display: none;
    height: 0;
    width: 0;
  }

  &::-ms-reveal {
    display: none;
    height: 0;
    width: 0;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

@mixin control-input {
  border: 1px solid $color-black-4;
  border-radius: rem-calc(30);
  display: block;
  line-height: normal;
  padding: rem-calc($height-input-icon / 2) rem-calc(20);
  width: 100%;

  &::placeholder {
    color: $color-black-4;
  }

  &::-webkit-input-placeholder { // sass-lint:disable-line no-vendor-prefixes
    line-height: normal;
  }

  &:focus {
    border: 1px solid $color-primary-2;
    box-shadow: 0 rem-calc(3) rem-calc(6) transparentize($color-black, .84), inset 0 0 0 1px $color-primary-2;
    outline: none;
  }
}

@mixin control-icon {
  @include icon-base;
  background: $color-black;
  border-radius: rem-calc($height-input-icon);
  bottom: rem-calc(($height-input-icon / 2) + $height-input-border-bottom);
  color: $color-white;
  font-size: rem-calc($height-input-icon);
  font-weight: bold;
  height: rem-calc($height-input-icon);
  pointer-events: none;
  position: absolute;
  right: rem-calc($height-input-icon / 2);
  width: rem-calc($height-input-icon);
}

// GROUP

.form-group {
  margin-bottom: rem-calc(20);
}

// LABEL

.form-group__label {
  display: block;
}

.form-group__label + .form-group__control {
  margin-top: rem-calc(10);
}

// CONTROL

.form-group__control {
  input {
    @include control-input;
  }
}

.form-group__control + .form-group__message {
  margin-top: rem-calc(10);
}

// CONTROL - SUCCESS & ERROR

.form-group--state-success,
.form-group--state-error {
  .form-group__control {
    position: relative;

    input,
    textarea,
    select {
      border-width: rem-calc($height-input-border-bottom);
    }

    &::after {
      @include control-icon;
    }
  }
}

// CONTROL - SUCCESS

.form-group--state-success {
  .form-group__control {
    input {
      border-color: $color-green-2;
    }

    &::after {
      background: $color-green-2;
      content: '\f007';
    }
  }
}

// CONTROL - ERROR

.form-group--state-error {
  .form-group__control {
    input,
    textarea,
    select {
      border-color: $color-red-1;
    }

    &::after {
      background: $color-red-1;
      content: '\f001';
      top: rem-calc(17);
    }
  }

  .form-group__message {
    color: $color-red-1;
  }

  .form-group__control__radios,
  .form-group__control__checkboxes {
    li {
      label {
        &::before {
          border: 1px solid $color-red-1;
          border-width: rem-calc($height-input-border-bottom);
        }
      }
    }
  }
}

// CONTROL - DISABLED

.form-group--state-disabled {
  .form-group__control {
    input {
      background-color: $color-white-2;
    }
  }
}

// CONTROL - SEARCH

.form-group__control--search {
  position: relative;

  input {
    @include control-input-remove-x;
  }

  button {
    border-radius: rem-calc(30);
    bottom: rem-calc(7);
    line-height: 1.2;
    position: absolute;
    right: rem-calc(7);
    top: rem-calc(7);

    span {
      &::before {
        @include icon-base;
        content: '\f009';
        font-size: rem-calc(34) !important; // sass-lint:disable-line no-important
        margin-left: rem-calc(-6);
      }
    }
  }
}

// CONTROL - SELECT

.form-group__control-select {
  // sass-lint:disable-block no-vendor-prefixes

  position: relative;

  &::after {
    $font-size: 30;
    @include icon-base;
    content: '\f05c';
    font-size: rem-calc($font-size);
    pointer-events: none;
    position: absolute;
    right: rem-calc(10);
    top: calc(50% - #{rem-calc($font-size / 2)});
  }

  select {
    @include control-input;
    appearance: none;
    background: none;
    line-height: normal;
    padding-right: rem-calc(44);

    &::-ms-expand {
      display: none;
    }
  }
}


@mixin control-checkbox-radio($checked: false, $border-corners: 100%) {
  left: -9999px;
  position: absolute;

  + label {
    color: $color-black;
    cursor: pointer;
    display: block;
    font-size: 22px;
    padding-bottom: rem-calc(4);
    padding-left: rem-calc(45);
    position: relative;

    &::before {
      border: 1px solid $color-black-4;
      border-radius: $border-corners;
      content: '';
      height: 30px;
      left: -4px;
      position: absolute;
      top: -4px;
      width: 30px;
    }

    &::after {
      position: absolute;
      @if ($checked) {
        opacity: 1;
        transform: scale(1);
      } @else {
        opacity: 0;
        transform: scale(0);
      }
    }
  }
}

// CONTROL - RADIO

.form-group__control-radio {
  padding: 0;

  &:checked {
    @include control-checkbox-radio($checked: true);

    + label {

      &::after {
        background-color: $color-red-1;
        border-radius: 100%;
        content: '';
        height: 10px;
        left: 6px;
        top: 6px;
        width: 10px;
      }
    }
  }

  &:not(:checked) {
    @include control-checkbox-radio($checked: false);
  }
}

// CONTROL - CHECKBOX

.form-group__control-checkbox {
  padding: 0;

  &:checked {
    @include control-checkbox-radio($checked: true, $border-corners: 6px);

    + label {

      &::before {
        background-color: $color-red-1;
      }

      &::after {
        background-color: transparent;
        border-radius: 6px;
        color: $color-white;
        content: '\f007'; // checkmark
        font-family: CityOfReginaWebsiteRedesign2018;
        font-size: 32px;
        height: 30px;
        left: -4px;
        text-indent: 0;
        top: -8px;
        width: 30px;
      }
    }
  }

  &:not(:checked) {
    @include control-checkbox-radio($checked: false, $border-corners: 6px);
  }
}

// CONTROL - RADIO/CHECKBOX - WRAPPER

.form-group__control__radios,
.form-group__control__checkboxes {
  list-style-type: none;

  li {
    min-height: 34px;
  }
}

// CONTROL - RADIO/CHECKBOX - FOCUS

.form-group__control-radio,
.form-group__control-checkbox {
  &:focus {

    &:not(:checked) {
      + label {
        outline: 0;

        &::before {
          background: $color-white-1;
        }
      }
    }
  }

  + label {

    &:focus {
      outline: 0;

      &::before {
        background: $color-white-1;
      }
    }
  }
}

// CONTROL - TEXTAREA

.form-group__control-textarea {
  textarea {
    @include control-input;
    resize: none;
  }
}
