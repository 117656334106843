.date-container {
  @include border-gradient-top(10px, $color-primary-2, $color-primary-1);
  @include font-foco-bold;
  display: inline-block;
  max-height: rem-calc(76);
  min-width: rem-calc(70);
  padding: rem-calc(6) 0;
  text-align: center;
  width: rem-calc(70);
}

.date-container--bordered {
  box-shadow: transparentize($color-black, .84) 1px 1px 10px;
}

.date__month {
  font-size: rem-calc(20);
  text-transform: uppercase;
}

.date__day {
  font-size: rem-calc(28);
}
