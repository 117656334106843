.heading--sidebar {
  @include font-foco-regular;
  font-size: rem-calc(30);
  line-height: (41 / 30);
  margin-bottom: rem-calc(20);
  margin-top: rem-calc(120);

  &:first-child {
    margin-top: rem-calc(30);
  }
}
