.nav--move-top {
  display: none;

  .fa {
    transform: rotate(270deg);
  }

  @media screen and (max-width: 1024px) {
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    opacity: 1;
    padding: 0 rem-calc(13) rem-calc(20) 0;
    position: sticky;
    transition: opacity 500ms;
    width: 100%;
    z-index: 1;

    &.hide {
      opacity: 0;
    }
  }
}
