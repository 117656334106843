//@import "compass/css3/font-face";

//@include font-face("UnitWebPro", font-files("UnitWebPro.woff"), "UnitWebPro.eot");
//@include font-face("UnitWebPro-Ita", font-files("UnitWebPro-Ita.woff"), "UnitWebPro-Ita.eot");
//@include font-face("UnitWebPro-Bold", font-files("UnitWebPro-Bold.woff"), "UnitWebPro-Bold.eot");

// sass-lint:disable no-duplicate-properties, indentation
@font-face {
  font-family: 'focoregular';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/foco-webfont.eot');
  src: url('../fonts/foco-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/foco-webfont.woff') format('woff'),
    url('../fonts/foco-webfont.ttf') format('truetype'),
    url('../fonts/foco-webfont.svg#focoregular') format('svg');
}

@font-face {
  font-family: 'foco_blackregular';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/focoblack-webfont.eot');
  src: url('../fonts/focoblack-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/focoblack-webfont.woff') format('woff'),
    url('../fonts/focoblack-webfont.ttf') format('truetype'),
    url('../fonts/focoblack-webfont.svg#foco_blackregular') format('svg');
}

@font-face {
  font-family: 'focobold';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/focobold-webfont.eot');
  src: url('../fonts/focobold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/focobold-webfont.woff') format('woff'),
    url('../fonts/focobold-webfont.ttf') format('truetype'),
    url('../fonts/focobold-webfont.svg#focobold') format('svg');
}

@font-face {
  font-family: 'foco_lightregular';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/focolight-webfont.eot');
  src: url('../fonts/focolight-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/focolight-webfont.woff') format('woff'),
    url('../fonts/focolight-webfont.ttf') format('truetype'),
    url('../fonts/focolight-webfont.svg#foco_lightregular') format('svg');
}

@mixin font-nunito-regular {
  font-family: 'Nunito', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
}

@mixin font-nunito-bold {
  font-family: 'Nunito', Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
}

@mixin font-foco-regular {
  font-family: 'focoregular', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
}

@mixin font-foco-bold {
  font-family: 'focobold', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
}
