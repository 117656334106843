.nav-mobile {
  @include list-unstyled;
  background: $color-white;
  border-bottom: 1px solid transparentize($color-black, .92);
  position: relative;

  li {
    border-top: 1px solid transparentize($color-black, .92);
    cursor: pointer;
  }

  a {
    display: block;
    font-size: rem-calc(18, 16);
    padding: rem-calc(20, 16);
    text-decoration: none;
  }

  [aria-current] {
    box-shadow: inset rem-calc(10) 0 0 0 $color-primary-2 !important; // sass-lint:disable-line no-important
    color: $color-black;
  }

  ul {
    // We use this to achieve a 3:1 contrast ratio
    background: transparentize($color-black, .93);

    a {
      padding-left: rem-calc(40);
    }

    ul {
      a {
        padding-left: rem-calc(60);
      }

      ul {
        a {
          padding-left: rem-calc(80);
        }
      }
    }
  }
}

.nav-mobile__toggle {
  position: relative;

  > ul {
    border-width: 0 !important; // sass-lint:disable-line no-important
    display: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 400ms ease-in-out;
  }

  > a {
    padding-right: rem-calc(58);
    transition: box-shadow 400ms ease-in-out;
  }

  &[aria-expanded='true'] {
    box-shadow: 0 1px 0 0 transparentize($color-black, .84);

    > ul {
      border-width: 1px !important; // sass-lint:disable-line no-important
      display: block;
      max-height: 100%;
    }

    > a {
      box-shadow: 0 rem-calc(3) rem-calc(6) transparentize($color-black, .84);
    }

  }
}

.nav-mobile__icon {
  cursor: pointer;
  line-height: rem-calc(82);

  &::after {
    box-sizing: content-box;
    color: $color-primary-2;
    content: '+';
    cursor: pointer;
    display: block;
    font-size: rem-calc(34);
    padding: 0 rem-calc(30) 0 rem-calc(20);
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: rem-calc(20);

    .nav-mobile__toggle[aria-expanded='true'] > & { // sass-lint:disable-line force-attribute-nesting
      content: '-';
    }
  }
}

.nav-mobile__close {
  color: $color-primary-2;
  cursor: pointer;
  font-size: rem-calc(40);
  line-height: 2.15;
  padding: 0 rem-calc(20);
  position: absolute;
  right: 0;
  top: 0;
}

.nav-mobile__quick-links {
  border-bottom: 1px solid transparentize($color-black, .92);
  padding: rem-calc(10) 0;

  ul {
    @include list-reset;

    li {
      padding: rem-calc(14, 16) rem-calc(18, 16);
    }
    }

  a {
    font-size: rem-calc(18, 16);
    text-decoration: none;
  }
}
