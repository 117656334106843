.nav-primary {
  @include layout-site-padding-horizontal;
  @include background-gradient-horizontal(93deg, transparentize($color-primary-2, .05), transparentize($color-primary-1, .05));
  @media print {
    display: none;
  }
}

.nav-primary__links {
  @include font-nunito-bold;
  @include fluid-type(1024px, 1800px, 16px, 22px);
  @include font-smoothing;
  @include layout-site-max;

  box-sizing: content-box;
  display: flex;

  a {
    @include button-10;
    flex-grow: 1;
  }

  .nav-primary__link {
    &-1 {
      flex-shrink: 1.75;
    }

    &-2 {
      flex-shrink: 2.5;
    }

    &-3 {
      flex-shrink: 1.75;
    }

    &-4 {
      flex-shrink: 2;
    }

    &-5 {
      flex-shrink: 3;
    }

    &-6 {
      flex-shrink: 1;
    }

    &-7 {
      flex-shrink: 2;
    }
  }
}

.nav-primary__flyout {
  -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-vendor-prefixes
  left: 0;
  max-height: calc(100vh - 150px);
  opacity: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 2;

  &.open {
    opacity: 1;
    visibility: visible;
  }
}

.nav-primary__content-wrapper {
  background-color: $color-white;
  display: flex;
  margin: 0 5vw;

  > div {
    width: 100%;
  }
  @media screen and (min-width: 1600px) {
    @include layout-site-max;
  }
}

.nav-primary__content {
  display: flex;
  min-height: 200px;
}

.nav-primary__content-body {
  flex: 1;
  padding: rem-calc(34);
  @media screen and (min-width: 1400px) {
    padding: rem-calc(50) rem-calc(96);
  }
}

.nav-primary__content-sidebar {
  background: $color-white-4;
  display: none;
  flex-basis: rem-calc(270);
  flex-direction: column;
  padding: rem-calc(34);
  @media screen and (min-width: 1200px) {
    display: flex;
  }
  @media screen and (min-width: 1400px) {
    padding: rem-calc(80) rem-calc(34);
  }

  h5 {
    @include font-nunito-bold;
    font-size: rem-calc(22);
    line-height: (25 / 20);
    margin-bottom: rem-calc(28);
  }

  p {
    margin-bottom: rem-calc(16);
  }

  ul {
    @include list-reset;

    li {
      margin-bottom: rem-calc(16);
    }

    a {
      @include font-foco-bold;
      display: inline-block;
      text-decoration: none;
    }
  }
}

.nav-primary__content-sidebar-top {
  flex: 1 1 auto;
  margin-bottom: rem-calc(25);
}

.nav-primary__content-title {
  @include font-foco-bold;
  font-size: rem-calc(30);
  line-height: (51 / 30);
  margin-bottom: rem-calc(32);

  a {
    text-decoration: none;
  }

  .fa {
    color: $color-primary-2;
    display: inline-block;
    font-size: 40px;
    padding-bottom: 4px;
    vertical-align: middle;
  }
}

.nav-primary__content-desc {
  @include p-intro;
  margin-bottom: rem-calc(40);
}

@media screen and (max-width: $breakpoint-offcanvas-max) {
  .nav-primary__links,
  .nav-primary__flyout {
    display: none;
  }
}
