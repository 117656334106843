.article__title {
  h1 {
    @include h1;
    margin-bottom: rem-calc(10);
  }
}

.article__meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: rem-calc(-10) 0 rem-calc(20);
}

.article__meta-text {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: rem-calc(10) rem-calc(30) 0 0;
}

.article__meta-links {
  align-items: center;
  display: flex;
  margin-top: rem-calc(10);
}

.article--listing__content {

  .article--listing__heading {
    margin-bottom: rem-calc(10);
  }
}

.article-image__listing {
  @media screen and (max-width: 767px) {
    margin: 0 -5vw rem-calc(50);

    &:last-child {
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: 768px) {
    display: flex;
    margin: 0 0 rem-calc(70);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.article-image__image {
  img {
    object-fit: cover;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    img {
      max-height: 200px;
    }

    .object-fit-polyfill {
      min-height: 30vw;
    }
  }
  @media screen and (min-width: 768px) {
    display: flex;
    flex-shrink: 0;
    min-width: 250px;
    width: 30%;

    .object-fit-polyfill {
      height: rem-calc(150);
      width: 100%;
    }
  }
}

.article-image__listing--alt {
  display: flex;
  flex: 1 1 0%;
  margin: 0 0 rem-calc(70);
  min-width: 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.article-image__image--alt {
  display: flex;
  flex-shrink: 0;
  min-width: 150px;
  width: 25%;

  picture {
    display: inline-block;
    width: 100%;
  }

  .object-fit-polyfill {
    height: rem-calc(150);
    width: 100%;
  }
}

.article-image__title {
  > h3 {
    @include h3;
  }

  > h4 {
    @include h4;
  }

  > h5 {
    @include h5;
  }

  > * {
    margin-bottom: rem-calc(20);
  }
}

.article-image__content {
  @media screen and (max-width: 767px) {
    padding: 5vw;
    padding-bottom: 0;
  }
  @media screen and (min-width: 768px) {
    padding: rem-calc(5) rem-calc(30);
  }
}

.article-image__body {
  margin-bottom: rem-calc(30);
}

.article-image__link {
  a {
    @include font-nunito-bold;
  }
}
