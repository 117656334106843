body {
  // padding-top: xxx; via JS on resize
  padding-top: rem-calc(80);

  &.body--vendor {
    padding-top: rem-calc(130);
  }
}

.page-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  @media screen and (max-width: $breakpoint-offcanvas-max) {
    box-shadow: 1px 0 11px 2px $color-white-10;
  }
}

.page-header__layout {
  background: $color-white;
  padding: 0 5vw;
}

.page-header__layout-inner {
  @include layout-site-max;
  align-items: center;
  display: flex;
}

.page-header__logo {
  flex: 1 1 auto;

  a {
    display: inline-block;
    padding: rem-calc(20) 0;
    vertical-align: middle;
  }

  img {
    width: rem-calc(132);
  }
}

.page-header__mobile-links {
  @media screen and (min-width: $breakpoint-desktop-menu-min) {
    display: none;
  }

  ul {
    @include list-reset;
    align-items: center;
    display: flex;
    margin: 0 rem-calc(10);

    li {
      padding: 0 rem-calc(10);
    }
  }

  button {
    @include button-reset;
    color: $color-primary-2;
  }

  i {
    font-size: rem-calc(35.2);
  }
}

.page-header__desktop-links {
  display: none;
  @media screen and (min-width: $breakpoint-desktop-menu-min) {
    display: block;
  }

  ul {
    @include list-reset;
    margin: 0 rem-calc(10);

    li {
      display: inline-block;
      padding: 0 rem-calc(10);
    }
  }

  a {
    text-decoration: none;
  }

  @media screen and (min-width: $breakpoint-desktop-menu-min) {
    display: block;
  }
}

.page-header__desktop-search {
  display: none;
  @media screen and (min-width: $breakpoint-desktop-menu-min) {
    display: block;
  }

  span {
    @extend %icon--search;

    &::before {
      font-size: rem-calc(34) !important; // sass-lint:disable-line no-important
      margin-left: rem-calc(-6);
    }
  }
}
