.featured-alert {
  background: $color-white;
  display: flex;
}

.featured-alert__image {
  flex-shrink: 0;
  width: 30%;

  img {
    display: block;
    width: 100%;
  }
}

.featured-alert__body {
  align-self: center;

  .featured-alert__title {
    h3 {
      @include font-nunito-bold;
      line-height: 1.2;
      margin-bottom: rem-calc(10);
    }

    a {
      text-decoration: none;
    }
  }
}

.featured-alert__link {
  margin-top: rem-calc(20);
}

.featured-alert--content {
  @include card-1;
  padding: rem-calc(25);

  .featured-alert__image {
    max-width: rem-calc(150);
    padding-right: rem-calc(25);

    @media screen and (max-width: 374px) {
      display: none;
    }
  }
}

.featured-alert--banner {
  border-radius: rem-calc(5);
  max-width: rem-calc(560);
  padding: rem-calc(16);

  .featured-alert__image {
    max-width: rem-calc(100);
    padding-right: rem-calc(16);

    img {
      border-radius: rem-calc(5);
      overflow: hidden;
    }
  }

  .featured-alert__title {
    h3 {
      margin-bottom: rem-calc(5);
    }
  }

  .featured-alert__content {
    font-size: rem-calc(18);
  }
}

.carousel--featured-alerts {
  .swiper-wrapper {
    box-shadow: 1px 1px 10px transparentize($color-black, .84);

    .featured-alert--content {
      box-shadow: none;
    }
  }
}
