.scrollable {
  overflow-x: auto;
}

.scrollable-wrap {
  ::-webkit-scrollbar { // sass-lint:disable-line no-vendor-prefixes
    -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes

    &:horizontal {
      height: 12px;
    }
  }

  ::-webkit-scrollbar-thumb { // sass-lint:disable-line no-vendor-prefixes
    background-color: transparentize($color-black, .5);
    border: 2px solid $color-white;
    border-radius: 10px;
  }
}
