.event-teaser {
  box-shadow: transparentize($color-black, .84) 1px 1px 10px;
  width: 100%;

  img {
    width: 100%;
  }
}

.event-teaser__content {
  display: flex;
  padding: rem-calc(12);
}

.event-teaser__title-location {
  align-self: center;
  padding-left: rem-calc(12);

  a {
    text-decoration: none;
  }
}

.event-teaser__location {
  font-size: rem-calc(16);
}

.event__date-share {
  border-bottom: 1px solid $color-white-11;
  justify-content: space-between;
  margin-bottom: rem-calc(12);
  padding-bottom: rem-calc(12);
  @media screen and (min-width: 768px) and (max-width: 1025px), (min-width: 1200px) {
    display: flex;
  }
  @media screen and (max-width: 480px) {
    @include layout-extend;
    padding-left: 5vw;
  }
}

.event__body {
  margin: rem-calc(24) 0;

  picture {
    max-width: 100%;
    width: 100%;

    @media screen and (min-width: 600px) {
      float: right;
      margin-left: rem-calc(10);
      max-width: 40%;
    }

    img {
      margin-bottom: rem-calc(12);
    }
  }
}
