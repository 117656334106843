.alert {
  align-content: center;
  background-color: $color-white;
  box-shadow: transparentize($color-black, .84) 1px 1px 10px;
  display: flex;
  font-size: rem-calc(22);
  padding: rem-calc(20) rem-calc(10) rem-calc(20) rem-calc(20);

  & > .fa {
    $alert-icon-size: rem-calc(30);
    background-color: $color-black-5;
    border-radius: $alert-icon-size;
    color: $color-white;
    flex-shrink: 0;
    font-size: $alert-icon-size;
    height: $alert-icon-size;
    margin: rem-calc(3) 0;
    width: $alert-icon-size;

    @media screen and (min-width: 768px) {
      $alert-icon-size: rem-calc(50);
      border-radius: $alert-icon-size;
      font-size: $alert-icon-size;
      height: $alert-icon-size;
      margin: 0;
      width: $alert-icon-size;
    }
  }

  .alert-body {
    line-height: 1.3;
    padding: rem-calc(5) rem-calc(14);
    @media screen and (min-width: 768px) {
      padding: rem-calc(11) rem-calc(14);
    }
  }

  .alert-title {
    @include font-nunito-bold;
    display: inline;
  }

  .alert-message {
    display: inline;
  }


  .alert-close {
    margin-left: auto;

    .fa {
      $alert-icon-size: rem-calc(40);
      background-color: transparent;
      color: $color-black-4;
      font-size: $alert-icon-size;
      height: $alert-icon-size;
      width: $alert-icon-size;
      @media screen and (min-width: 768px) {
        $alert-icon-size: rem-calc(50);
        font-size: $alert-icon-size;
        height: $alert-icon-size;
        width: $alert-icon-size;
      }
    }

    &:focus,
    &:hover {
      background-color: transparent;
    }
  }

  .alert-button {
    display: block;
    margin-top: rem-calc(16);
  }
}



.alert--success {
  @include border-gradient-top(10px, $color-green-2, $color-green-1);

  .fa {
    background-color: $color-green-2;
  }
}

.alert--error {
  @include border-gradient-top(10px, $color-red-1, $color-red-2);

  .fa {
    background-color: $color-red-1;
  }
}

.alert--warning {
  @include border-gradient-top(10px, $color-red-4, $color-red-5);

  .fa {
    background-color: $color-red-4;
  }
}

.alert--info {
  @include border-gradient-top(10px, $color-primary-2, $color-primary-1);

  .fa {
    background-color: $color-primary-2;
  }
}

.alert--emergency {
  @include font-smoothing;
  @include font-nunito-bold;
  background: $color-red-1;
  color: $color-white;

  .alert__inner {
    padding: rem-calc(20) 5vw;
    position: relative;
  }

  .alert__close {
    cursor: pointer;
    font-size: rem-calc(50);
    position: absolute;
    right: 5vw;
    top: rem-calc(4.4);
  }

  .alert__header {
    display: flex;
  }

  .alert__header-icon {
    i {
      background-color: $color-white;
      border-radius: 50%;
      box-shadow: 0 0 0 rem-calc(6) transparentize($color-white, .8);
      color: $color-red-1;
      font-size: rem-calc(24);
    }
  }

  .alert__header-title {
    font-size: rem-calc(22);
    min-width: 190px;
    padding: 0 rem-calc(20);
    text-transform: uppercase;
  }

  .alert__body {
    @include font-nunito-regular;
    font-size: rem-calc(18);
  }

  .alert__link {
    margin-top: rem-calc(5);

    a {
      color: $color-white;
      font-size: rem-calc(18);
      text-decoration: none;
      white-space: nowrap;

      span {
        text-decoration: underline;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .alert__header {
      margin-bottom: rem-calc(10);
      padding-right: rem-calc(20);
    }
  }
  @media screen and (min-width: 768px) {
    .alert__inner {
      box-sizing: content-box;
      display: flex;
      margin: 0 auto;
      max-width: 1600px;
      padding: rem-calc(20) 5vw;
    }

    .alert__header-icon {
      i {
        font-size: rem-calc(50);
      }
    }

    .alert__header-title {
      padding: 0 rem-calc(30);
      width: rem-calc(220);
    }

    .alert__body {
      padding-right: rem-calc(50);
    }
  }
}
