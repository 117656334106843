@mixin banner-title {
  @include font-foco-bold;
  @include font-smoothing;
  @include fluid-type(375px, 1600px, 35px, 50px);
  color: $color-white;
  line-height: (76 / 60);
}

@mixin banner-gradient-top-to-bottom {
  background: linear-gradient(0deg, $color-blue-6, transparent 50%);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@mixin banner-gradient-left-to-right {
  background: linear-gradient(90deg, $color-black, transparent 50%);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.page-banner__wrapper {
  @media screen and (min-width: 1023px) {
    // Used to provide a min-height if no banner is selected
    min-height: 75px;
}
}

.page-banner {
  position: relative;
}

.page-banner__overlay {
  @media screen and (min-width: 768px) {
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
}

.page-banner__alerts {
  padding: 0 5vw;
  position: absolute;
  top: 100px;
  width: 100%;
  @media screen and (max-width: 1025px) {
    display: none;
  }

  > div {
    margin: 0 auto;
    max-width: 1600px;
    position: relative;

    > div {
      position: absolute;
      right: 0;
    }
  }

  .swiper-container {
    max-width: rem-calc(560);
  }
}

.page-banner__nav-main {
  flex: 1 1 auto;
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.page-banner__image {
  position: relative;

  picture {
    display: block;
    position: relative;

    &::before {
      @include banner-gradient-left-to-right;
      content: '';
      z-index: 1;
    }

    &::after {
      @include banner-gradient-top-to-bottom;
      content: '';
      z-index: 1;
    }

    img {
      object-fit: cover;
      width: 100%;
    }
  }

  .page-banner--home & {
    picture {
      img,
      &.object-fit-polyfill {
        background: $color-white-5;
        max-height: rem-calc(425);
        min-height: rem-calc(320);
        @media screen and (min-width: 768px) {
          max-height: none;
          min-height: 520px;
        }
        @media screen and (min-width: 1200px) {
          min-height: 750px;
        }
        @media screen and (min-width: 1200px) {
          min-height: 950px;
        }
      }
    }
  }

  .page-banner--landing & {
    picture {
      img,
      &.object-fit-polyfill {
        background: $color-white-5;
        max-height: rem-calc(425);
        min-height: rem-calc(320);
        @media screen and (min-width: 768px) {
          max-height: none;
          min-height: 520px;
        }
        @media screen and (min-width: 1025px) {
          min-height: 600px;
        }
        @media screen and (min-width: 1281px) {
          max-height: 750px;
          min-height: 600px;
        }
      }
    }
  }
}

.page-banner__image-title {
  bottom: rem-calc(50);
  padding: 0 5vw;
  position: absolute;
  z-index: 2;

  h1 {
    @include banner-title;
    @include layout-site-max;
    box-sizing: content-box;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }

}

.page-banner__title-more {
  @include layout-site-padding-horizontal;
  position: relative;
  z-index: 2;
}

.page-banner__title {
  //@include layout-site-padding-horizontal;
  @include layout-site-max;
  display: none;

  h1 {
    @include banner-title;
  }
  @media screen and (min-width: 768px) {
    display: block;
    margin-bottom: rem-calc(40);

    h1 {
      margin: 0;

      .page-banner--home & {
        max-width: rem-calc(600);
      }
    }
  }
}

.page-banner__more {
  @include layout-site-max;
  @media screen and (min-width: 768px) {
    > div {
      margin: 0;
      max-width: rem-calc(600);
    }
  }
}

.page-banner__search {
  margin-top: rem-calc(-34);
  @media screen and (min-width: 768px) {
    margin: 0;
    margin-bottom: rem-calc(40);
  }
}

.page-banner__weather {
  align-items: center;
  display: flex;
  @media screen and (max-width: 767px) {
    margin: 0 auto;
    padding: rem-calc(37) 5vw;
  }
  @media screen and (min-width: 768px) {
    color: $color-white;
    margin-bottom: rem-calc(40);
    max-width: rem-calc(600);
    text-shadow: 1px 1px 1px $color-black;
  }
}

.page-banner__weather-icon {
  .fa {
    color: $color-black;
    font-size: rem-calc(33);
    line-height: (57.75 / 33);

    &::before {
      display: block;
    }
  }
  @media screen and (min-width: 768px) {
    .fa {
      color: $color-white;
    }
  }
}

.page-banner__weather-temp {
  @include font-foco-regular;
  font-size: rem-calc(48);
  padding: 0 rem-calc(19) 0 rem-calc(7);

  &::after {
    content: '\00b0';
  }
}

.page-banner__weather-desc {
  @include font-nunito-regular;
  font-size: rem-calc(22);
  line-height: 1.5;
  @media screen and (min-width: 768px) {
    border-left: 1px solid $color-white;
    padding-left: rem-calc(19);

    a {
      color: $color-white;
    }
  }
}

.page-banner__nav-bottom {
  @include background-gradient-horizontal(80deg, transparentize($color-primary-2, .3), transparentize($color-primary-1, .3));
  overflow: hidden;

  .page-banner--home & {
    display: none;
    @media screen and (min-width: 1200px) {
      display: block;
      padding: 3vw 5vw;

      ul {
        @include list-scroll(1vw, false);
        margin: 0 -1vw;
      }
    }
  }

  .page-banner--landing & {
    padding-top: 3vw;

    ul {
      @include list-scroll(1vw);
      margin: 0 -1vw;
      padding-bottom: 3vw;
    }
  }
}

.page-banner__nav-bottom-inner {
  @include layout-site-max;
}


.page-banner__nav-home {
  @include background-gradient-horizontal(80deg, transparentize($color-primary-2, .1), transparentize($color-primary-1, .1));
  padding: 3vw 5vw;

  > div {
    @include layout-site-max;
    box-sizing: content-box;
  }

  ul {
    @include list-unstyled;
    @include layout-flex-container(wrap, 15);
    margin-top: rem-calc(15);

    li {
      @include layout-flex-columns(2, 15);
      display: flex;
      flex-direction: column;
      margin-bottom: rem-calc(15);
      @media screen and (min-width: 768px) {
        @include layout-flex-columns(3, 15);
      }
    }

    a {
      flex: 1 1 auto;
    }
  }
  @media screen and (min-width: 1200px) {
    display: none;
  }
}
