.container {
  border: 2px dashed $color-blue-2;

  &__content {
    background-color: transparentize($color-blue-2, .7);
    padding: 20px;
  }

  &--empty {
    border-color: $color-red-6;

    &__content {
      background-color: transparentize($color-red-6, .7);
      padding: 20px;
    }
  }

  &--offline {
    border-color: $color-primary-2;

    &__content {
      background-color: transparentize($color-primary-2, .7);
      padding: 20px;
    }
  }
}
