@mixin card-1 {
  @include border-gradient-top(10px, $color-primary-2, $color-primary-1);
  box-shadow: transparentize($color-black, .84) 1px 1px 10px;

  &:focus {
    box-shadow: 0 0 0 3px $color-blue-3;
  }

  &.card-1--hoverable {
    &:hover {
      @include border-gradient-top(10px, $color-red-1, $color-red-3);
    }
  }

  &.card-1--disabled {
    background: $color-white-2;
    border-image: none;
    border-top-color: transparentize($color-black, .7);
    min-height: 200px;
  }
}

@mixin card-2 {
  background-color: $color-white-6;
}
