.grid-2 {
  margin: 0 -10px;

  > * {
    display: flex;
    flex-wrap: wrap;

    > * {
      margin: 0 5px;
      width: calc(50% - 10px);
    }
  }
}
