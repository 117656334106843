.display-table-md {
  display: none;
  @media screen and (min-width: 1025px) {
    display: table;
  }
}

.display-table-sm {
  display: none;
  @media screen and (min-width: 768px) {
    display: table;
  }
}

.display-block-sm {
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
}

.display-none-md {
  @media screen and (min-width: 1025px) {
    display: none;
  }
}

.display-none-sm {
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.display-none-mobile {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
