$column-gap: 25px;

.contact--simple {
  display: flex;
  flex-wrap: wrap;

  .contact__headline {
    flex-basis: 100%;
  }

  > .contact__column {
    flex-basis: 100%;
    margin-bottom: $column-gap;

    @media screen and (min-width: 650px) {
      @include flex-columns-with-gap(2, $column-gap);
      margin-right: $column-gap;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @media screen and (min-width: 1000px) {
      @include flex-columns-with-gap(4, $column-gap);

      &:nth-child(2n) {
        margin-right: $column-gap;
      }

      &:last-child {
        margin-right: 0;
      }
    }

  }
}

.contact--detailed {
  .contact--detailed__content {
    padding: 25px;
  }

  .contact__row {
    display: flex;
    flex-wrap: wrap;

    .contact__column {
      flex-basis: 100%;
      @media screen and (min-width: 650px) {
        @include flex-columns-with-gap(2, $column-gap);
        margin-right: $column-gap;

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      @media screen and (min-width: 1000px) {
        @include flex-columns-with-gap(4, $column-gap);
        margin-right: $column-gap;

        &:nth-child(2n) {
          margin-right: $column-gap;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &:last-child {
    .contact__column {
      flex-basis: 100%;
      @media screen and (min-width: 650px) {
        @include flex-columns-with-gap(2, $column-gap);
        margin-right: $column-gap;

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}
