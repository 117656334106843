.social-card-links {
  ul {
    font-size: rem-calc(48);
    margin: 0 0 0 rem-calc(-10);
    padding: 0;

    li {
      display: inline-block;
      padding: 0;

      &::before {
        content: none;
      }
    }
  }
}
