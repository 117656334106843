.fact {
  .fact__text {
    @include font-foco-regular;
    font-size: rem-calc(50);
    line-height: (63 / 50);
    margin: rem-calc(50 - (51-40));
    margin-left: 0;
  }

  a {
    @include font-nunito-bold;
    font-size: rem-calc(22);
    line-height: (28 / 22);
  }
}
