$opencms-hash: rgba(230, 230, 232, 1);

.megamenu-info {
  table {
    @include card-1;

    td,
    th {
      border-bottom: 1px solid $color-white-7;
      padding: 18px 25px;
      text-align: left;
    }

    thead {
      tr {
        background-color: $color-white-6;
      }
    }
  }
}

// Move the edit target below the main nav on the home page banner
.page-banner__wrapper {
  #banner { // sass-lint:disable-line no-ids
    .org-opencms-gwt-client-ui-css-I_CmsDirectEditCss-optionBar {
      top: 82px;
    }
  }
}

.admin__expired-item {
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    $opencms-hash 10px,
    $opencms-hash 20px
  );
  opacity: .6;
}
