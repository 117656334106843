// CARD 1 - CONTENT 1

.card-1-content-1 {
  padding: rem-calc(26) rem-calc(34);
}

.card-1-content-1__title {
  @include font-nunito-bold;
  font-size: rem-calc(20);
  line-height: (28 / 20);
  margin-bottom: rem-calc(18);

  a {
    text-decoration: none;
  }
}

.card-1-content-1__body {
  color: $color-black;
  font-size: rem-calc(20);
  line-height: (28 / 20);
  margin-bottom: rem-calc(18);
  word-break: break-word;

  a {
    word-break: break-word;
  }
}

.card-1-content-1__date {
  color: $color-primary-9;
  font-size: rem-calc(16);
  line-height: (22 / 16);
}

// CARD 1 - CONTENT 2

.card-1-content-2 {
  padding: rem-calc(26) rem-calc(34);
}

.card-1-content-2__title {
  @include h4;
  line-height: 1.4;
  margin-bottom: rem-calc(18);
}

.card-1-content-2__body {
  line-height: 1.4;
  margin-bottom: rem-calc(18);

  p {
    margin-bottom: rem-calc(18);
  }
}

// CARD 1 - CONTENT 3

.card-1-content-3__image {
  img {
    width: 100%;
  }
}

.card-1-content-3__content {
  a {
    text-decoration: none;
  }
}

.card-1-content-3__content-title {
  @include h3;
  margin: rem-calc(34);
}

.card-1-content-3__list-accordion {
  padding: rem-calc(26) rem-calc(34);

  .card-1-content-3__list {
    margin-top: rem-calc(22);
    @media screen and (min-width: 768px) {
      margin: 0;
    }
  }
}

.card-1-content-3__list-main {
  @include list-unstyled;
  border-bottom: 1px solid $color-white-2;
  border-top: 1px solid $color-white-2;
  padding: rem-calc(26) rem-calc(34);

  span {
    display: block;
    font-size: rem-calc(16);
    margin-bottom: rem-calc(4);
  }
}

.card-1-content-3__list {
  @include list-unstyled;
  column-gap: rem-calc(30);

  li {
    break-inside: avoid-column;
    display: block;
    margin-bottom: rem-calc(15);
  }

  span {
    display: block;
    font-size: rem-calc(16);
    margin-bottom: rem-calc(4);
  }

  .disabled-text {
    color: $color-black-5;
    font-size: rem-calc(20);
    margin-bottom: rem-calc(0);
  }

  @media screen and (min-width: 768px) {
    columns: 2;
  }

}


// CARD 2 - CONTENT 1

.card-2-content-1 {
  padding: 25px;
}

.card-2-content-1__title {
  font-size: rem-calc(26);
  line-height: 1.4;
  margin-bottom: rem-calc(10);
}

.card-2-content-1__body {
  line-height: 1.4;
}
