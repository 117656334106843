.author {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-right: rem-calc(30);
}

.author__image {
  height: auto;
  margin: rem-calc(10) rem-calc(15) 0 0;
  width: 75px;

  picture {
    display: block;

    img {
      border-radius: 50%;
      box-shadow: rem-calc(3) rem-calc(3) rem-calc(10) transparentize($color-black, .84);
    }
  }
}

.author_information {
  margin-top: rem-calc(10);

  .author__name {
    margin-bottom: rem-calc(5);
  }
}


.author__sm {
  .author__image {
    width: 50px;

  }

  .author_information {
    font-size: rem-calc(16);
  }
}
