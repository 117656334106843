.list--related-documents {
  @include list-unstyled;
  margin: 0 rem-calc(-15);
}

.list--popular-pages {
  @media screen and (max-width: 767px) {
    ul {
      @include list-scroll(rem-calc(10), true, calc(5vw - #{rem-calc(10)}), 30vw);
    }
  }

  @media screen and (max-width: 650px) {
    ul {
      @include list-scroll(rem-calc(10), true, calc(5vw - #{rem-calc(10)}), 40vw);
    }
  }

  @media screen and (max-width: 500px) {
    ul {
      @include list-scroll(rem-calc(10), true, calc(5vw - #{rem-calc(10)}), 55vw);
    }
  }


  @media screen and (min-width: 768px) {
    overflow: visible;

    ul {
      @include list-flex(0, rem-calc(10));
    }
  }
  @media screen and (min-width: 1025px) {
    ul {
      @include list-flex-wrap(50%, rem-calc(10));
    }
  }
  @media screen and (min-width: 1400px) {
    ul {
      @include list-flex(25%, rem-calc(10));
    }
  }
}

.list--looking-for {
  margin: 0;
  padding: 0;

  dt,
  dd {
    margin: 0;
    padding: 0;
  }

  dt {
    @include font-nunito-bold;
    margin-bottom: rem-calc(15);

    a {
      text-decoration: none;
    }
  }

  dd {
    margin-bottom: 30px;
  }
}

.list--horizontal-borders {
  @include list-reset;

  li {
    position: relative;

    &::before {
      @include list-border;
      top: -1px;
    }

    &::after {
      @include list-border;
      bottom: -1px;
    }
  }
}

.list--in-this-section {
  @include list-unstyled;
  column-gap: rem-calc(30);
  overflow: hidden;

  .list__heading {
    @include h3;
    padding-top: rem-calc(14);
    position: relative;

    a,
    span {
      @include h3;
    }

    a {
      @include font-foco-bold;
    }
  }

  .list__heading-icon {
    position: absolute;
    right: 0;
    top: rem-calc(22);
  }

  .list__in-this-section__items {
    a {
      @include h3;
      @include font-foco-bold;
    }
  }

  a,
  span {
    display: block;
    padding: rem-calc(10) 0;
    text-decoration: none;
  }

  // sass-lint:disable-block no-vendor-prefixes
  > li {
    -webkit-column-break-inside: avoid;
    break-inside: avoid-column;
    display: block;
    page-break-inside: avoid;
  }

  @media screen and (max-width: 767px) {
    > li {
      // Use borders for mobile
      // Some browsers will not render a 1px box shadow well on mobile
      border-bottom: 1px solid transparentize($color-black, .5);
      padding-bottom: rem-calc(13);

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  @media screen and (min-width: 768px) {
    columns: 2;

    > li {
      // Use top box-shadow for tablet+
      // CSS columns will hide overflow on first elements in each column
      box-shadow: 0 -1px 0 0 transparentize($color-black, .5);
      margin-bottom: rem-calc(13);
    }
  }

  @media screen and (min-width: 1024px) {
    columns: 3;
  }

}

.list--recommended {
  @media screen and (max-width: 1024px) {
    @include list-scroll(rem-calc(10), true, 3.75vw, 30vw);
  }

  @media screen and (max-width: 650px) {
    @include list-scroll(rem-calc(10), true, 3.75vw, 40vw);
  }

  @media screen and (max-width: 500px) {
    @include list-scroll(rem-calc(10), true, 3.75vw, 55vw);
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: -5vw;
    padding-bottom: 5vw;
  }

  @media screen and (min-width: 1025px) {
    @include list-grid(4, 20);
  }

  a {
    @include button-5-sm;

    &[rel*='external'],
    span {
      display: inline-block;
      width: auto;
    }

    .fa {
      display: block;
      font-size: 100px;
    }
  }
}

.list--events {
  @media screen and (max-width: 1024px) {
    @include list-scroll(rem-calc(10), true, calc(5vw - #{rem-calc(12)}), rem-calc(275));
    @include layout-extend-right;

    li {
      margin: rem-calc(12);
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media screen and (min-width: 1025px) {
    @include list-grid(2, 20);

    li {
      $margin-base: 12;
      margin: rem-calc(2*$margin-base) rem-calc($margin-base);
      $left-and-right-margins-total: rem-calc(2*$margin-base);
      max-width: calc(50% - #{$left-and-right-margins-total});
      padding: 0;
    }
  }
}

.list--map-embeds {
  -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-vendor-prefixes

  @media screen and (max-width: 1024px) {
    @include list-scroll(rem-calc(10), true, calc(5vw - #{rem-calc(12)}), rem-calc(275));
    @include layout-extend-right;

    li {
      margin: rem-calc(12);
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media screen and (min-width: 1025px) {
    @include list-grid(3, 20);

    li {
      $margin-base: 12;
      margin: rem-calc(2*$margin-base) rem-calc($margin-base);
      $left-and-right-margins-total: rem-calc(2*$margin-base);
      max-width: calc(33% - #{$left-and-right-margins-total});
      padding: 0;

      > div {
        flex-grow: 0;
      }
    }
  }
}


.list--two-column-tablet {
  @media screen and (min-width: 768px) {
    columns: 2;
  }
}
