.search-results {
  .highlight {
    @include font-nunito-bold;
    background-color: $color-blue-9;
    padding: 0 rem-calc(5);
  }

  .form-control__input-search {
    margin-bottom: rem-calc(40);
  }

  .form-control__sort {
    margin-bottom: rem-calc(40);
  }

  .form-control__facets {
    margin-bottom: rem-calc(40);
  }

  .search-results__result-message {
    @include p-note;
    border-bottom: 1px solid $color-white-10;
    margin-bottom: rem-calc(30);
    padding-bottom: rem-calc(30);
  }
}

.search-results-result {
  border-bottom: 1px solid $color-white-10;
  margin-bottom: rem-calc(35);
  padding-bottom: rem-calc(40);

  a {
    @include font-nunito-bold;
    display: block;
    font-size: rem-calc(20);
    line-height: (28 / 20);
    margin-bottom: rem-calc(18);
    text-decoration: none;
  }
}

.search-results-result,
.search-results__result-message {
  @media screen and (max-width: 1024px) {
    @include layout-extend;
    @include layout-site-padding-horizontal;
  }
}

// BYLAWS - RESULTS
.bylaw-list-item {

  a {
    display: inline-block;
    margin-bottom: rem-calc(6);
  }

  &__number {
    white-space: nowrap;
  }
}
