.l-scrollable {
  @media screen and (max-width: 767px) {
    @include layout-scrollable(10, 250);
    @include layout-flex-equal-height;
  }
  @media screen and (min-width: 768px) {
    @include layout-flex-container(nowrap, 10);
    @include layout-flex-equal-height;

    > div {
      @include layout-flex-columns(3, 10);
    }
  }
}
