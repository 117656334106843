.share {
  align-items: center;
  @media screen and (min-width: 481px) {
    display: flex;
    flex-wrap: wrap;
  }

  span {
    margin-right: rem-calc(6);
  }

  ul {
    @include list-unstyled;
    font-size: rem-calc(50);
  }

  li {
    display: inline-block;
  }

  &--sm {
    ul {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.share--lg {
  @media screen and (max-width: 480px) {
    @include layout-extend;
  }
}
