.l-nav-content {
  @media screen and (min-width: $breakpoint-desktop-menu-min) {
    @include layout-site-padding-horizontal;
    background: linear-gradient(
      to bottom,
      transparentize($color-primary-10, .88),
      transparentize($color-white, .88)
    );
    background-repeat: no-repeat;
    background-size: 50%;
  }
}

.l-nav-content__inner {
  @media screen and (min-width: $breakpoint-desktop-menu-min) {
    @include layout-site-max;
    display: flex;
  }
}

.l-nav-content__content {

  @include layout-module-spacing;

  main > :first-child {
    margin-top: rem-calc(30);
  }

  @media screen and (min-width: $breakpoint-desktop-menu-min) {
    background-color: $color-white;
    flex-grow: 0;
    flex-shrink: 0;
    order: 2;
    padding-bottom: rem-calc(150);
    padding-left: rem-calc(125);
    padding-top: rem-calc(80);
    width: 66%;

    .module {
      padding: 0;
    }

    .module--light,
    .module--dark {
      padding: rem-calc(30);
    }

    .module--border-top {
      border-top: 1px solid $color-white-8;
      padding-top: calc(10px + 1vw);
    }
  }

  @media screen and (min-width: 1280px) {
    width: 72%;
  }

}

.l-nav-content__nav {
  background-color: $color-white-3;
  padding: 5vw;
  @media screen and (min-width: $breakpoint-desktop-menu-min) {
    background: none;
    flex-grow: 0;
    flex-shrink: 0;
    padding-bottom: rem-calc(150);
    padding-left: 0;
    padding-right: rem-calc(30);
    padding-top: rem-calc(132);
    width: 34%;
  }

  @media screen and (min-width: 1280px) {
    width: 28%;
  }

  .module {
    margin-bottom: calc(10px + 4vw);
  }

  .module--md {
    margin-bottom: calc(10px + 3vw);
  }

  .module--sm {
    margin-bottom: calc(10px + 2vw);
  }

  @media screen and (min-width: 1600px) {
    .module {
      margin-bottom: rem-calc(75);
    }

    .module--md {
      margin-bottom: rem-calc(50);
    }

    .module--sm {
      margin-bottom: rem-calc(25);
    }
  }

  .module--secondary {
    display: none;
    @media screen and (min-width: $breakpoint-desktop-menu-min) {
      display: block;
    }
  }
}
