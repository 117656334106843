.featured-location {
  @include card-1;
  margin: 0;
  @media screen and (min-width: 768px) and (max-width: 1025px), (min-width: 1400px) {
    position: relative;
  }

  @media screen and (min-width: 1025px) {
    margin: 0;
  }

  @media screen and (min-width: 1400px) {
    display: flex;
  }
}

.featured-location__map {
  min-height: 300px;
  position: relative;
  width: 100%;

  @media screen and (min-width: 1400px) {
    flex-basis: 50%;
    min-height: inherit;
  }
}

.featured-location__map-iframe {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  iframe {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.featured-location__content {
  flex-basis: 50%;
  @media screen and (min-width: 1025px) {
    min-height: 300px;
  }
}

.featured-location__content-inner {
  padding: rem-calc(40) rem-calc(25);
}

.featured-location__items {
  @media screen and (min-width: 600px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (min-width: 768px) {
    @include layout-flex-container(wrap, 10);
  }
}

.featured-location__item {
  margin-bottom: rem-calc(20);
  width: 100%;
  @media screen and (min-width: 768px) {
    @include layout-flex-columns(2, 10);
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 1025px) {
    @include layout-flex-columns(1, 10);
  }

  ul {
    @include list-unstyled;
    font-size: rem-calc(18);
    line-height: 1.4;
  }

  address {
    margin-bottom: rem-calc(10);
  }

  a {
    font-size: rem-calc(18);
    white-space: nowrap;
  }
}

.featured-location__title {
  @include h4;
  line-height: 1.4;
  margin-bottom: rem-calc(18);
}
