.module--light {
  background-color: $color-white-6;
}

.module--dark {
  @include background-gradient-horizontal(122deg, $color-primary-2, $color-primary-1);

  &,
  a {
    @include font-smoothing;
    color: $color-white;
  }
}

.module--featured-alerts {
  @media screen and (min-width: 1025px) {
    display: none;
  }
}

.module__title > * {
  @include h2;
  margin-bottom: rem-calc(25 - (41-30));
}

div + .module__title {
  padding-top: rem-calc(40);
}

// MODULE - TOGGLE GROW

.module--toggle-grow {
  .toggle-grow {
    // leave room for card box shadows
    margin-left: rem-calc(-6);
    margin-right: rem-calc(-6);
    padding-left: rem-calc(6);
    padding-right: rem-calc(6);
  }
  @media screen and (max-width: 767px) {
    .module__title {
      .toggle-grow-trigger__text {
        border-bottom: 1px solid $color-black-5;
        padding-bottom: rem-calc(10);

        h2 {
          width: calc(100% - 35px);
        }
      }
    }
  }
}
