%accordion-hide-arrow {
  summary {
    list-style: none;

    &::-webkit-details-marker { // sass-lint:disable-line no-vendor-prefixes
      display: none;
    }
  }
}

.accordion {

  details {
    @extend %accordion-hide-arrow;

    summary {
      @include font-nunito-bold;
      border-bottom: 1px solid $color-white-8;
      padding: rem-calc(15) rem-calc(22);
      position: relative;

      &::after {
        @include icon-base;
        color: $color-primary-2;
        content: '\f01d';
        font-size: rem-calc(20);
        position: absolute;
        right: rem-calc(20);
        transform: rotate(90deg);
      }

      // This is to remove the icon added from the details-polyfill in IE.
      // We do not use before anywhere else on this element so this should not override anything.
      &::before {
        content: '' !important; //sass-lint:disable-line no-important
        width: auto !important; //sass-lint:disable-line no-important
      }
    }

    &[open] {
      summary {
        &::after {
          transform: rotate(-90deg);
        }

        // This is to remove the icon added from the details-polyfill in IE.
        // We do not use before anywhere else on this element so this should not override anything.
        &::before {
          content: '' !important; //sass-lint:disable-line no-important
          width: auto !important; //sass-lint:disable-line no-important
        }
      }
    }

    &:first-child {
      summary {
        border-top: 1px solid $color-white-8;
      }
    }
  }
}

.accordion__content {
  background-color: $color-white-6;
  border-bottom: 1px solid $color-white-8;
  padding: rem-calc(16) rem-calc(20);
}
