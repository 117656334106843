.councillor {
  margin: 0 -5vw;
  @media screen and (min-width: 1025px) {
    margin: 0;
  }
}

.councillor__profile {
  border-bottom: rem-calc(2) solid $color-white-5;
  @media screen and (max-width: 767px) {
    align-items: center;
    display: flex;
    padding: 5vw;
  }
  @media screen and (min-width: 768px) {
    position: relative;
  }
}

.councillor__image {
  picture {
    display: block;

    img {
      border-radius: 50%;
      box-shadow: rem-calc(3) rem-calc(3) rem-calc(10) transparentize($color-black, .84);
    }
  }
  @media screen and (max-width: 767px) {
    flex: 0 0 auto;
    max-width: rem-calc(200);
    min-width: rem-calc(100);
    width: 27vw;
  }
  @media screen and (min-width: 768px) {
    left: 5vw;
    position: absolute;
    top: -12.5vw;
    width: 25vw;
  }
  @media screen and (min-width: 1025px) {
    left: rem-calc(30);
    top: -8vw;
    width: 16vw;
  }
  @media screen and (min-width: 1600px) {
    left: rem-calc(30);
    top: rem-calc(-128);
    width: rem-calc(256);
  }
}

.councillor__title {
  $left1: rem-calc(40);
  $left2: rem-calc(70);
  $left3: rem-calc(256);
  @media screen and (max-width: 767px) {
    flex: 1 1 auto;
    padding-left: rem-calc(25);
  }
  @media screen and (min-width: 768px) {
    padding: 5vw;
    padding-left: calc(30vw + #{$left1});
  }
  @media screen and (min-width: 1025px) {
    padding: rem-calc(30);
    padding-left: calc(16vw + #{$left2});
  }
  @media screen and (min-width: 1600px) {
    padding: rem-calc(30);
    padding-left: calc(#{$left3} + #{$left2});
  }
}

.councillor__name {
  h1 {
    @include h1;
    line-height: 1;
  }
}

.councillor__ward {
  h3 {
    @include h3;
    line-height: 1;
    margin-bottom: rem-calc(12);

    span {
      display: none;
    }
  }
  @media screen and (min-width: 767px) {
    h3 {
      span {
        display: inline;
      }
    }
  }
}

.councillor__contact {
  border-bottom: rem-calc(2) solid $color-white-5;
  padding: 5vw;
  @media screen and (min-width: 1025px) {
    padding: rem-calc(22);
  }
}

.councillor__contact-layout {
  @media screen and (min-width: 768px) {
    display: flex;
  }
}

.councillor__contact-section {
  ul {
    @include list-unstyled;
  }
}

.councillor__contact-section-heading {
  h4 {
    @include h4;
    line-height: 1;
    margin-bottom: rem-calc(10);
  }

  li {
    display: inline-block;
    margin: 0 rem-calc(20) rem-calc(10) 0;
  }
  @media screen and (min-width: 768px) {
    flex: 1 0 0;
  }
}

.councillor__contact-section-website {
  a {
    text-decoration: none;

    span {
      text-decoration: underline;
    }
  }
}

.councillor__contact-section-social {
  ul {
    font-size: rem-calc(36);
  }

  li {
    display: inline-block;
  }

  h5 {
    margin-top: rem-calc(5);
    white-space: nowrap;
  }
}

.councillor-article__listing {
  @media screen and (min-width: 600px) {
    display: flex;
  }

  .councillor-article__image {
    max-width: 100%;
    width: 100%;

    @media screen and (min-width: 600px) {
      margin-left: rem-calc(20);
      max-width: 40%;
      order: 1;
    }
  }

  .councillor-article__title {
    margin-bottom: rem-calc(20);

    h4 {
      @include h4;
    }
  }

  .councillor-article__heading {
    margin-bottom: rem-calc(20);
  }

  .councillor-article__body {
    margin-bottom: rem-calc(20);
  }

}
