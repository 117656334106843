.blocker {
  background: linear-gradient(36deg, $color-blue-7, $color-blue-8 10%, $color-primary-1 40%, $color-primary-1);
  z-index: 5;
}

.modal {
  .content {
    padding-right: rem-calc(44);
  }

  a {
    &.modal__close {
      background: none;
      bottom: auto;
      height: auto;
      left: auto;
      right: rem-calc(-11);
      text-indent: 0;
      top: rem-calc(-22);
      width: auto;

      &::after {
        @include icon-base;
        color: $color-white;
        content: '\f005';
        font-size: rem-calc(66);
      }
    }
  }
}

.modal--dark {
  background: none;
  box-shadow: none;
  max-width: 800px;
  padding: 0;
  width: 100%;
}

.modal--gateway {
  .modal__close {
    display: none !important; // sass-lint:disable-line no-important
  }
}
